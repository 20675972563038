// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import _ from 'lodash';
import { types } from 'mobx-state-tree';
import IdpType from './IdpType';

const Idp = types.model('Idp', {
  entityId: types.identifier,
  status: 'active',
  name: '',
  desc: types.optional(types.string, ''),
  xmlMetadata: '',
  type: IdpType,
  createdAt: '',
  updatedAt: '',
  rev: 0,
}).views(self => ({
  get isActive() {
    return _.toLower(self.status) === 'active';
  },
}));

export default Idp;

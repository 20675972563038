import React from 'react';
import _ from 'lodash';
import { action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import {
  Button, Form, Grid, Header, Image, Segment, Label, Input,
} from 'semantic-ui-react';

import logoImage from '../images/sig-blk-en.svg';
import { displayError } from '../helpers/notification';
import { AuthenticationStore } from '../models/AuthenticationStore';
import LoginState from '../models/component-states/LoginState';

interface OwnProps {
  // Declare regular properties here
  loginState: Instance<typeof LoginState>
}

type ComponentProps =
  { authenticationStore: Instance<typeof AuthenticationStore> } // Properties being injected by Mobx React
  & OwnProps // Component's own regular properties that should be passed by the parent component

// From https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/app/Layouts/LoginLayout.js
class Login extends React.Component<ComponentProps> {

  getLoginState() {
    return this.props.loginState;
  }

  handleChange = (fieldName: string, setterFn: Function) => action((event: any) => {
    const loginState = this.getLoginState();

    setterFn(event.target.value);

    if (fieldName === 'username') loginState.setUserNameError('');
    if (fieldName === 'password') loginState.setPasswordError('');
  });

  handleLogin = action((event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const loginState = this.getLoginState();
    loginState.setUserNameError('');
    loginState.setPasswordError('');

    const username = _.trim(loginState.username) || '';
    const password = loginState.password || '';
    let error = false;

    if (_.isEmpty(username)) {
      loginState.setUserNameError('username is required');
      error = true;
    }

    if (!_.isEmpty(username) && (username.length < 3)) {
      loginState.setUserNameError('username must be at least 3 characters long');
      error = true;
    }

    if (_.isEmpty(password)) {
      loginState.setPasswordError('password is required');
      error = true;
    }
    if (!_.isEmpty(password) && (password.length < 4)) {
      loginState.setPasswordError('password must be at least 4 characters long');
      error = true;
    }

    if (error) return;

    const authenticationStore = this.props.authenticationStore;
    loginState.setLoading(true);

    Promise.resolve()
      .then(() => authenticationStore.login({username, password}))
      .catch((err) => displayError(err))
      .finally(action(() => { loginState.setLoading(false); }));
  });

  render() {
    const loginState = this.getLoginState();
    const error = !!((loginState.usernameError || loginState.passwordError));
    return (
      <div className="login-form animated fadeIn">
        {/*
        Heads up! The styles below are necessary for the correct render of this example.
        You can do same with CSS, the main idea is that all the elements up to the `Grid`
        below must have a height of 100%.
      */}
        <style>{`
        body > div#root,
        body > div#root > div,
        body > div#root > div > div.login-form {
          height: 100%;
        }
      `}</style>
        <Grid
          textAlign="center"
          style={{height: '100%'}}
          verticalAlign="middle"
        >
          <Grid.Column style={{maxWidth: 600}}>
            <Form error={error} size="large" loading={loginState.loading} onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}>
              <Segment stacked>
                <Image centered src={logoImage} className='mt3 mb4 ml3 mr3'/>
                <Header as="h3" textAlign="center">
                  Service Provider Management
                  <Header.Subheader>
                    Identity Service
                  </Header.Subheader>
                </Header>

                <Form.Field error={!!loginState.usernameError} required>
                  <Input
                    fluid
                    icon="user"
                    iconPosition="left"
                    placeholder="Username"
                    value={loginState.username}
                    onChange={this.handleChange('username', loginState.setUserName.bind(loginState))}/>
                  {loginState.usernameError &&
                  <Label basic color="red" pointing className="float-left mb2">{loginState.usernameError}</Label>}
                </Form.Field>

                <Form.Field error={!!loginState.passwordError} required>
                  <Input
                    fluid
                    icon="lock"
                    iconPosition="left"
                    placeholder="Password"
                    value={loginState.password}
                    type="password"
                    onChange={this.handleChange('password', loginState.setPassword.bind(loginState))}/>
                  {loginState.passwordError &&
                  <Label basic color="red" pointing className="float-left mb2">{loginState.passwordError}</Label>}
                </Form.Field>
                <Button type="submit" color="blue" fluid basic size="large" className="mb2"
                        onClick={this.handleLogin}>Login</Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default inject('authenticationStore')(observer(Login));

// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import { createForm } from '../../helpers/form';

const formFields = {
  name: {
    label: 'Application Client Name',
    extra: {
      explain: 'Name of the application client. This is mainly used for display purpose and easier identification. '
        + 'This is a required field and the number of characters must be between 3 and 100. ',
    },
    placeholder: 'Enter application client name.',
    rules: 'string|required|between:3,100|regex:/^[a-zA-Z0-9_-]+$/',
  },
  callbackUrls: {
    label: 'Callback URLs',
    extra: {
      explain: 'After successful login using the service provider the service provider will allow the client to be redirected to any one of the specified Callback URLs. Any login request using the service provider with redirect_uri value not matching any Callback URL specified here will be rejected.',
    },
    placeholder: 'Enter comma separated list of allowed callback urls for this application client',
  },
  logoutUrls: {
    label: 'Logout URLs',
    extra: {
      explain: 'After successful logout from service provider the service provider will allow the client to be redirected to any one of the specified Logout URLs. Any logout request using the service provider with redirect_uri value not matching any Logout URL specified here will be rejected.',
    },
    placeholder: 'Enter comma separated list of allowed logout urls for this application client',
  },
  allowedOAuthFlows: {
    label: 'Allowed OAuth Flows',
    extra: {
      explain: 'OAuth flows enabled for this app. The application will be able to request corresponding grant. See OAuth 2.0 Grant Types for more details. '
        + 'Currently only "Implicit" and "Client Credentials" grants are supported.',
    },
  },
};

function getFormFields() {
  return formFields;
}

function getForm(fields = formFields) {
  return createForm(fields);
}

export { getForm, getFormFields };

// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

const awsRegion = process.env.REACT_APP_AWS_REGION;
const websiteUrl = process.env.REACT_APP_WEBSITE_URL;
const userPoolId = process.env.REACT_APP_USER_POOL_ID;
const userPoolClientAppId = process.env.REACT_APP_USER_POOL_CLIENT_ID;
const userPoolDomain = process.env.REACT_APP_USER_POOL_DOMAIN;
const spMgmtApiUrl = process.env.REACT_APP_SP_MGMT_API_URL;

export {
  awsRegion,
  websiteUrl,
  userPoolId,
  userPoolClientAppId,
  userPoolDomain,
  spMgmtApiUrl,
};

// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import { Instance, types } from 'mobx-state-tree';
import AppClient from '../app-client/AppClient';

const AddAppClientState = types.model('AppClientListItemCardState', {
  appClientAdded: types.maybe(AppClient),
}).actions(self => ({
  setAppClientAdded(appClientAdded: Instance<typeof AppClient> | undefined) {
    self.appClientAdded = appClientAdded;
  },
}));

export default AddAppClientState;

// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import _ from 'lodash';
import { SnapshotIn } from 'mobx-state-tree';
import { Auth } from 'aws-amplify';
import { parseError, delay } from './utils';
import { spMgmtApiUrl } from './settings';
import KeyValuePairs from '../models/KeyValuePairs';
import { User } from '../models/User';
import Idp from '../models/idp/Idp';
import AppClient from '../models/app-client/AppClient';

let config = {
  baseApiPath: spMgmtApiUrl,
  fetchMode: 'cors',
  maxRetryCount: 4,
};

let token: string | undefined;
const authHeader = (token?: string) => ({ Authorization: `Bearer ${token}` });

function setAccessToken(accessToken: string) {
  token = accessToken;
}

function forgetAccessToken() {
  token = undefined;
}

function configure(obj: any) {
  config = Object.assign({}, config, obj);
}

interface FetchJsonOptions extends RequestInit {
  params?: KeyValuePairs;
}

function fetchJson(url: string, options: FetchJsonOptions = {}, retryCount: number = 0) {
  // see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
  let isOk = false;
  let httpStatus: number = 0;

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  const body = '';
  const merged: FetchJsonOptions = Object.assign({}, {
    method: 'GET',
    cache: 'no-cache',
    mode: config.fetchMode,
    redirect: 'follow',
    body,
  }, options, { headers: Object.assign({}, headers, options.headers) });

  if (merged.method === 'GET') delete merged.body; // otherwise fetch will throw an error

  if (merged.params) {
    // if query string parameters are specified then add them to the URL
    // The merged.params here is just a plain JavaScript object with key and value
    // For example {key1: value1, key2: value2}

    // Get keys from the params object such as [key1, key2] etc
    const paramKeys = _.keys(merged.params);

    // Filter out params with undefined or null values
    const paramKeysToPass = _.filter(paramKeys, key => !_.isNil(_.get(merged.params, key)));
    const query = _.map(paramKeysToPass, key => `${encodeURIComponent(key)}=${encodeURIComponent(_.get(merged.params, key))}`)
      .join('&');
    url = query ? `${url}?${query}` : url;
  }

  function retry(): Promise<any> {
    let backoff = retryCount * retryCount;
    if (backoff < 1) backoff = 1;

    return Promise.resolve()
      .then(() => console.log(`Retrying count = ${retryCount}, Backoff = ${backoff}`))
      .then(() => delay(backoff))
      .then(() => fetchJson(url, options, retryCount + 1));
  }

  return Promise.resolve()
    .then(() => fetch(url, merged))
    .catch(err => {
      // this will capture network/timeout errors, because fetch does not consider http Status 5xx or 4xx as errors
      if (retryCount < config.maxRetryCount) {
        return retry();
      }
      throw parseError(err);
    })
    .then(response => {
      isOk = response.ok;
      httpStatus = response.status;
      return response;
    })
    .then(response => {
      if (_.isFunction(response.text)) return response.text();
      return response;
    })
    .then(text => {
      let json;
      try {
        if (_.isObject(text)) {
          json = text;
        } else {
          json = JSON.parse(text);
        }
      } catch (err) {
        if (httpStatus >= 400) {
          if (httpStatus >= 501 && retryCount < config.maxRetryCount) {
            return retry();
          }
          throw parseError({
            message: text,
            status: httpStatus,
          });
        }
        throw parseError(new Error('The server did not return a json response.'));
      }

      return json;
    })
    .then(json => {
      if (_.isBoolean(isOk) && !isOk) {
        throw parseError(json);
      } else {
        return json;
      }
    });
}

// ---------- helper functions ---------------

// @ts-ignore
function httpApiGet(urlPath: string, { params }: KeyValuePairs = {}) {
  return fetchJson(`${config.baseApiPath}/${urlPath}`, {
    method: 'GET',
    headers: authHeader(token),
    params,
  });
}

// @ts-ignore
function httpApiPost(urlPath: string, { data, params }: KeyValuePairs = {}) {
  return fetchJson(`${config.baseApiPath}/${urlPath}`, {
    method: 'POST',
    headers: authHeader(token),
    params,
    body: JSON.stringify(data),
  });
}

// @ts-ignore
function httpApiPut(urlPath: string, { data, params }: KeyValuePairs = {}) {
  return fetchJson(`${config.baseApiPath}/${urlPath}`, {
    method: 'PUT',
    headers: authHeader(token),
    params,
    body: JSON.stringify(data),
  });
}

// @ts-ignore
function httpApiDelete(urlPath: string, { data, params }: KeyValuePairs = {}) {
  return fetchJson(`${config.baseApiPath}/${urlPath}`, {
    method: 'DELETE',
    headers: authHeader(token),
    params,
    body: JSON.stringify(data),
  });
}

async function getCurrentUser(): Promise<SnapshotIn<typeof User>> {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  return {
    username: cognitoUser.username,
    firstName: _.get(cognitoUser, 'attributes.name'),
    lastName: _.get(cognitoUser, 'attributes.family_name'),
    email: _.get(cognitoUser, 'attributes.email'),
  };
}

// ---------- api calls ---------------
async function getIdps(): Promise<SnapshotIn<typeof Idp>[]> {
  return httpApiGet('api/idps');
}

async function addIdp(idp: SnapshotIn<typeof Idp>): Promise<SnapshotIn<typeof Idp>> {
  return httpApiPost('api/idps', { data: idp });
}

async function updateIdp(entityId: string, rev: number, idp: SnapshotIn<typeof Idp>): Promise<SnapshotIn<typeof Idp>> {
  return httpApiPut(`api/idps/${encodeURIComponent(entityId)}`, { data: { rev, ...idp } });
}

async function activateIdp(entityId: string, rev: number): Promise<SnapshotIn<typeof Idp>> {
  return httpApiPut(`api/idps/${encodeURIComponent(entityId)}/activate`, { data: { rev } });
}
async function deactivateIdp(entityId: string, rev: number): Promise<SnapshotIn<typeof Idp>> {
  return httpApiPut(`api/idps/${encodeURIComponent(entityId)}/deactivate`, { data: { rev } });
}
async function deleteIdp(entityId: string) {
  return httpApiDelete(`api/idps/${encodeURIComponent(entityId)}`);
}

async function getAppClients(): Promise<SnapshotIn<typeof AppClient>[]> {
  return httpApiGet('api/app-clients');
}

async function addAppClient(appClient: Omit<SnapshotIn<typeof AppClient>, 'id'>): Promise<SnapshotIn<typeof AppClient>> {
  return httpApiPost('api/app-clients', { data: appClient });
}

async function updateAppClient(id: string, rev: number, appClient: SnapshotIn<typeof AppClient>): Promise<SnapshotIn<typeof AppClient>> {
  const appClientUpdateBody = _.omit({ rev, ...appClient }, 'id');
  return httpApiPut(`api/app-clients/${encodeURIComponent(id)}`, { data: appClientUpdateBody });
}

async function resetAppClientSecret(id: string, rev: number): Promise<SnapshotIn<typeof AppClient>> {
  return httpApiPut(`api/app-clients/${encodeURIComponent(id)}/secret`, { data: { rev } });
}

async function deleteAppClient(id: string) {
  return httpApiDelete(`api/app-clients/${encodeURIComponent(id)}`);
}

export {
  configure,
  setAccessToken,
  forgetAccessToken,
  config,
  getCurrentUser,

  // API functions that talk to server side
  getIdps,
  addIdp,
  updateIdp,
  activateIdp,
  deactivateIdp,
  deleteIdp,
  getAppClients,
  addAppClient,
  updateAppClient,
  resetAppClientSecret,
  deleteAppClient,
};

// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import _ from 'lodash';

import { types } from 'mobx-state-tree';

enum IdpTypes {
  GCKey = 'GCKey',
  SecureKey = 'SecureKey',
  OtherSAML2Idp = 'OtherSAML2Idp',
}

const IdpType = types.enumeration<IdpTypes>('IdpType', Object.values(IdpTypes));

function getIdpTypeOptions() {
  const idpTypes = Object.values(IdpTypes);
  return _.map(idpTypes, idpTypeValue => ({
    key: idpTypeValue,
    value: idpTypeValue,
    text: idpTypeValue,
  }));
}

export { getIdpTypeOptions };
export default IdpType;

// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import Amplify from 'aws-amplify';
import { storage } from './helpers/utils';
import { awsRegion, userPoolId, userPoolClientAppId } from './helpers/settings';

function initializeAmplify() {
  Amplify.configure({
    Auth: {
      // Amazon Cognito Region
      region: awsRegion,

      // Amazon Cognito User Pool ID
      userPoolId,

      // Amazon Cognito Application Client ID (26-char alphanumeric string)
      userPoolWebClientId: userPoolClientAppId,

      // Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,

      // customized storage object
      storage,
    },
  });
}

export default initializeAmplify;

import _ from 'lodash';
import React from 'react';
import {observer} from 'mobx-react';
import {Message} from 'semantic-ui-react';
import {Err} from "../../Err";
import {Instance} from "mobx-state-tree";

interface OwnProps {
  error?: Instance<typeof Err> | string,
  className?: string
}

class ErrorBox extends React.Component<OwnProps> {

  render() {
    const defaultMessage = 'Hmm... something went wrong';
    const rawMessage = this.props.error || defaultMessage;
    const message = _.isString(rawMessage) ? rawMessage : _.get(rawMessage, 'message', defaultMessage);
    const className = this.props.className ? this.props.className : 'p3';

    return (
      <div className={`${className}`}>
        <Message negative className="clearfix">
          <Message.Header>A problem was encountered</Message.Header>
          <p>{message}</p>
        </Message>
      </div>
    );
  }
}

export default observer(ErrorBox);

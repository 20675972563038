// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import { types } from 'mobx-state-tree';

import { SnapshotIn } from 'mobx-state-tree/dist/core/type/type';
import { parseError, CustomErrorAttributes } from './helpers/utils';

const Err = types.model('Err', {
  message: '',
  code: types.optional(types.string, ''),
  requestId: types.optional(types.string, ''),
});

const toErr = (error: Error & Partial<CustomErrorAttributes>) => {
  const parsed = parseError(error);
  const errObj: SnapshotIn<typeof Err> = {
    message: parsed.message || '',
    code: parsed.code || '',
    requestId: parsed.requestId || '',
  };
  return Err.create(errObj);
};

export {
  Err,
  toErr,
};

import React from "react";
import {Button, Container, Header, Icon, Segment} from "semantic-ui-react";
import {RouteComponentProps, withRouter} from "react-router";
import {inject, observer} from "mobx-react";
import {Instance} from "mobx-state-tree";
import _ from 'lodash';

import {csvToNonEmptyStringArr, swallowError} from "../../helpers/utils";
import {isStoreError, isStoreLoading, isStoreReady} from "../../models/BaseStore";
import ErrorBox from "../helpers/ErrorBox";
import BasicProgressPlaceholder from "../helpers/BasicProgressPlaceholder";
import Input from '../helpers/fields/Input';
import Form from '../helpers/Form';
import {gotoFn} from "../../helpers/routing";
import {displayError, displaySuccess} from "../../helpers/notification";

import {getForm as getEditAppClientForm} from "../../models/forms/AppClientFormModel"
import AppClient from "../../models/app-client/AppClient";
import {AppClientStore} from "../../models/app-client/AppClientStore";
import DropDown from "../helpers/fields/DropDown";
import {getAuthFlowOptions} from "../../models/app-client/AuthFlow";

interface OwnProps {
  // Declare regular properties here
}

interface PathParams {
  // Declare any params coming from the router for example if you have "/some/url/:someParam" route
  // then declare "someParam" here
  id: string
}

type ComponentProps =
  { appClientStore: Instance<typeof AppClientStore> } // Properties being injected by Mobx React
  & RouteComponentProps<PathParams> // Path params coming from the route url
  & OwnProps // Component's own regular properties that should be passed by the parent component

/**
 * Component for listing configured identity providers
 */
class EditAppClient extends React.Component<ComponentProps> {
  private readonly editAppClientForm: any;

  constructor(props: ComponentProps) {
    super(props);
    this.editAppClientForm = getEditAppClientForm();
  }

  componentDidMount() {
    const store = this.getStore();
    swallowError(store.load());
  }

  render() {
    const store = this.getStore();
    let content = null;

    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} className="p0"/>;
    } else if (isStoreLoading(store)) {
      content = <BasicProgressPlaceholder segmentCount={3}/>;
    } else if (isStoreReady(store)) {
      content = this.renderMain();
    } else {
      content = null;
    }

    return (
      <Container className="mt3 mb4">
        {content}
      </Container>
    );
  }

  protected getStore() {
    return this.props.appClientStore;
  }

  protected getAppClientId() {
    return decodeURIComponent((this.props.match.params || {}).id);
  }

  protected getAppClientBeingEdited(): Instance<typeof AppClient> | undefined {
    const id = this.getAppClientId();
    return this.getStore().getAppClient(id);
  }

  protected renderTitle(appClient: Instance<typeof AppClient>) {
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="computer" className="align-top"/>
          <Header.Content className="left-align">{appClient.name}</Header.Content>
        </Header>
      </div>
    );
  }

  protected renderMain() {
    const id = this.getAppClientId();
    const appClientBeingEdited: Instance<typeof AppClient> | undefined = this.getAppClientBeingEdited();
    if (!appClientBeingEdited) {
      return <ErrorBox error={this.noAppClientConfiguredError(id)}/>;
    }

    const form = this.editAppClientForm;

    const nameField = form.$('name');
    nameField.value = appClientBeingEdited.name;

    const callbackUrlsField = form.$('callbackUrls');
    callbackUrlsField.value = appClientBeingEdited.callbackUrls;

    const logoutUrlsField = form.$('logoutUrls');
    logoutUrlsField.value = appClientBeingEdited.logoutUrls;

    const authFlowsField = form.$('allowedOAuthFlows');
    authFlowsField.value = appClientBeingEdited.allowedOAuthFlows;

    const authFlowOptions = getAuthFlowOptions();

    return <React.Fragment>
      {this.renderTitle(appClientBeingEdited)}
      <Segment clearing className="p3">
        <Form form={form} onCancel={this.handleCancel} onSuccess={this.handleFormSubmission}
              onError={this.handleFormError}>
          {({processing, onCancel}: { processing: boolean, onCancel: any }) =>
            <React.Fragment>

              <Input field={nameField} disabled={processing}/>
              <Input field={callbackUrlsField} disabled={processing}/>
              <Input field={logoutUrlsField} disabled={processing}/>
              <DropDown options={authFlowOptions} field={authFlowsField} selection={true} fluid={true} clearable={true} multiple={true} search={true} disabled={processing}/>

              <div className="mt3">
                <Button floated="right" color="blue" icon disabled={processing} className="ml2" type="submit">Update
                  Application Client</Button>
                <Button floated="right" disabled={processing} onClick={onCancel}>Cancel</Button>
              </div>
            </React.Fragment>
          }
        </Form>
      </Segment>
    </React.Fragment>
  }

  protected handleCancel = () => {
    const goto = gotoFn(this);
    goto('/app-clients');
  };

  protected handleFormError = (_form: any, _errors: any) => {
    // We don't need to do anything here
  };

  protected noAppClientConfiguredError(id: string) {
    return `Cannot update the application client. No application client with id ${id} is configured.`
  }

  protected handleFormSubmission = async (form: any) => {
    const {name, callbackUrls, logoutUrls, allowedOAuthFlows} = form.values();

    const store = this.getStore();
    try {
      await store.updateAppClient(this.getAppClientId(), {
        id: this.getAppClientId(),
        name,
        callbackUrls: csvToNonEmptyStringArr(callbackUrls),
        logoutUrls: csvToNonEmptyStringArr(logoutUrls),
        allowedOAuthFlows: _.isEmpty(allowedOAuthFlows) ? [] : allowedOAuthFlows, // Make sure to send emtpy array for allowedOAuthFlows when it's empty
      });

      displaySuccess('The identity provider is updated successfully');
      form.clear();

      const goto = gotoFn(this);
      goto('/app-clients');
    } catch (error) {
      displayError(error);
    }
  }
}

export default inject('appClientStore')(withRouter(observer(EditAppClient)));

import React from 'react';
import {observer} from 'mobx-react';
import {Header as SemanticUiHeader} from 'semantic-ui-react';

// expected props
// - field (via props), this is the mobx form field object
// - className (via props)
const Header = observer(({field, className = 'mt0 mb1'}) => {
  const {id, label} = field;

  return (
    <SemanticUiHeader className={`field ${className}`} as="h3">
      <label className="color-grey" htmlFor={id}>
        {label}
      </label>
    </SemanticUiHeader>
  );
});

export default Header;

import React from 'react';
import { observer } from 'mobx-react';
import { Message } from 'semantic-ui-react';

// expected props
// - field (via props), this is the mobx form field object
const Description = observer(({ field }) => {
  const { extra = {} } = field;
  const explain = (extra ||{}).explain;
  const warn = (extra || {}).warn;
  const hasExplain = !!explain;
  const hasWarn = !!warn;

  return (
    <React.Fragment>
      {hasExplain && <div className="field mb2" dangerouslySetInnerHTML={{ __html: explain }}/>}
      {hasWarn && <Message className="field" color="brown"><b className="mr1">Warning</b> {warn}</Message>}
    </React.Fragment>
  );
});

export default Description;

import _ from 'lodash';
import React from 'react';
import { observer } from 'mobx-react';
import { Input as SemanticUiInput} from 'semantic-ui-react';

import Header from './Header';
import Description from './Description';
import ErrorPointer from './ErrorPointer';

// expected props
// - field (via props), this is the mobx form field object
// - className (via props)
//
// The following props are to support existing React Semantic UI props:
// - fluid (via props), default to true
// - disabled (via props), default to false
const Input = observer(({ field, fluid = true, disabled = false, className = 'mb4' }) => {
  const { error = '' } = field;
  const hasError = !_.isEmpty(error); // IMPORTANT do NOT use field.hasError
  const isDisabled = field.disabled || disabled;
  const disabledClass = isDisabled? 'disabled': '';
  const errorClass = hasError? 'error': '';
  const attrs = {
    fluid,
    disabled: isDisabled,
    error: hasError,
    ..._.omit(field.bind(), ['label']),
  };

  return (
    <div className={`${className} ${errorClass} ${disabledClass}`}>
      <Header field={field}/>
      <Description field={field}/>
      <SemanticUiInput className="field" {...attrs}/>
      <ErrorPointer field={field}/>
    </div>
  );
});

export default Input;

import _ from 'lodash';
import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {Menu, Icon, Flag} from 'semantic-ui-react';
import {Instance} from 'mobx-state-tree';
import {inject, observer} from "mobx-react";

import {createLink} from '../helpers/routing';
import {displayError} from '../helpers/notification';
import RouterProps from "../models/RouterProps";
import {AuthenticationStore} from "../models/AuthenticationStore";
import {CurrentUserStore} from "../models/CurrentUserStore";

interface OwnProps {
  // Declare regular properties here
}
interface PathParams {
  // Declare any params coming from the router for example if you have "/some/url/:someParam" route
  // then declare "someParam" here
}

type ComponentProps =
  RouterProps // Standard properties being injected by the React Router (via withRouter function)
  & {
    currentUserStore: Instance<typeof CurrentUserStore>
    authenticationStore: Instance<typeof AuthenticationStore>
  } // Properties being injected by Mobx React
  & RouteComponentProps<PathParams> // Path params coming from the route url
  & OwnProps // Component's own regular properties that should be passed by the parent component

class MainLayout extends React.Component<ComponentProps> {

  goto = (pathname: string) => () => {
    const location = this.props.location;
    const link = createLink({
      location,
      pathname,
    });

    this.props.history.push(link);
  };

  handleLogout = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    this.props.authenticationStore.logout()
      .then(() => {
        // window.location = "/";
        this.goto('/');
      })
      .catch(displayError);
  };

  render() {
    const pathname = _.get(this.props.location, 'pathname', '');
    const is = (value:string) => _.startsWith(pathname, value);
    const isListIdps = is('/idps');
    const isListAppClients = is('/app-clients');

    const currentUser = this.props.currentUserStore.user;
    return [
      <Menu vertical inverted fixed="left" icon="labeled" key="ml1">
        <Menu.Item name=" " style={{height: '40px'}}/>
        <Menu.Item name="idps" active={isListIdps} onClick={isListIdps ? undefined : this.goto('/')}>
          <Icon name="user secret" size="mini"/>
          <span className="fs-7">Identity Providers</span>
        </Menu.Item>
        <Menu.Item name="appClients" active={isListAppClients} onClick={isListAppClients ? undefined : this.goto('/app-clients')}>
          <Icon name="computer" size="mini"/>
          <span className="fs-7">Application Clients</span>
        </Menu.Item>
      </Menu>,

      <Menu inverted fixed="top" className="box-shadow zindex-1500" key="ml2">
        <Menu.Item className="m0 pt2 pl2 pr2" style={{height: '30px'}}>
           {/*<Image size="small" src={logoImage} className="mr1" style={{ maxHeight: '100px' }}/>*/}
          <Flag name='canada' />
          Service Provider Management Application
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item><Icon name="user"/> {currentUser && currentUser.displayName}</Menu.Item>
          <Menu.Item name="logout" onClick={this.handleLogout}/>
        </Menu.Menu>
      </Menu>,
      <div className="fit animated fadeIn" style={{
        paddingTop: '40px',
        paddingLeft: '84px',
        paddingBottom: '100px',
      }} key="ml3">
        {this.props.children}
      </div>,
    ];
  }
}

export default inject('authenticationStore', 'currentUserStore')(withRouter(observer(MainLayout)));


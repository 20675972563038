// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import _ from 'lodash';
import { Auth } from 'aws-amplify';
import jwtDecode from 'jwt-decode';
import { getEnv } from 'mobx-state-tree';

import { getFragmentParam, removeFragmentParams, storage } from '../helpers/utils';
import { BaseStore } from './BaseStore';
import { ContextModels } from '../contextModels';
import { boom } from '../helpers/errors';
import { setAccessToken } from '../helpers/api';
import {
  awsRegion, userPoolDomain, websiteUrl, userPoolClientAppId,
} from '../helpers/settings';

const isTokenExpired = (token: string) => {
  const now = Date.now();
  const decoded = jwtDecode(token);
  const expiresAt = _.get(decoded, 'exp', 0) * 1000;
  return expiresAt < now;
};

function removeTokensFromUrl() {
  const newUrl = removeFragmentParams(document.URL, [
    'id_token', 'access_token', 'token_type', 'expires_in',
  ]);
  window.history.replaceState({}, document.title, newUrl);
}

interface UserNamePasswordCredentials {
  username: string, password: string
}
// ==================================================================
// Login model
// ==================================================================
const AuthenticationStore = BaseStore.named('AuthenticationStore')
  .actions(self => ({
    // this method is called by the Cleaner
    cleanup() {
      storage.removeItem('access_token');
    },

    async getAccessTokenFromAmplify() {
      try {
        // The Auth.currentSession() line below throws "No current user" error when user has not signed in yet
        // Also the Auth.currentSession() works only in case of direct cognito user pool auth. It throws exception in
        // case of federated login via SAML or other social IdPs
        // This mechanism of retrieving jwtToken from current session only works for cognito user pool auth and not SAML.
        // Due to this, we still need to manage the accessToken on our own in local storage if we want to support SAML login
        // for SP Management Application in future.
        const currentSession = await Auth.currentSession();
        const token = _.get(currentSession, 'accessToken.jwtToken');
        return token;
      } catch (e) {
        console.error(e);
      }
    },

    async getAccessTokenFromUrl() {
      const accessTokenFromUrl = getFragmentParam(document.URL, 'access_token');
      if (accessTokenFromUrl) {
        // we remove the access_token from the url for a good security measure
        removeTokensFromUrl();

        // save id token into local storage
        // @ts-ignore
        await self.saveAccessToken(accessTokenFromUrl);
      }
      return accessTokenFromUrl;
    },

    async getAccessTokenFromLocal() {
      return storage.getItem('access_token');
    },

    async getActiveAccessToken() {
      // @ts-ignore
      const accessTokenFromUrl = await self.getAccessTokenFromUrl();
      // @ts-ignore
      const accessTokenFromLocal = await self.getAccessTokenFromLocal();
      // @ts-ignore
      const accessTokenFromAmplify = await self.getAccessTokenFromAmplify();

      const accessToken = accessTokenFromAmplify || accessTokenFromUrl || accessTokenFromLocal;
      let activeAccessToken;
      if (accessToken && !isTokenExpired(accessToken)) {
        activeAccessToken = accessToken;
      }
      return activeAccessToken;
    },
    async login(credentials: UserNamePasswordCredentials) {
      // Post username and password to cognito user pool and get id token
      const cognitoResponse = await Auth.signIn(credentials.username, credentials.password);

      if (cognitoResponse.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // If password change is required then navigate to cognito's page to let it handled by cognito built in hosted ui
        const loginUri = `https://${userPoolDomain}.auth.${awsRegion}.amazoncognito.com/login?response_type=token&client_id=${userPoolClientAppId}&redirect_uri=${websiteUrl}`;
        window.location.href = loginUri;
      } else {
        const accessToken = _.get(cognitoResponse, 'signInUserSession.accessToken.jwtToken');

        if (_.isEmpty(accessToken)) {
          // @ts-ignore
          throw boom.incorrectImplementation('There is a problem with the implementation of the server side code. The id token is not returned.');
        }

        // @ts-ignore
        await self.saveAccessToken(accessToken);

        const { appStore } = getEnv(self);
        await appStore.start();
      }
    },

    async saveAccessToken(accessToken: string) {
      // self.setAccessTokenFromLocal(accessToken)
      setAccessToken(accessToken);
    },

    async logout() {
      await Auth.signOut({ global: true });
      const { cleaner } = getEnv(self);
      return cleaner.cleanup();
    },
  }));

function registerModels(models: ContextModels) {
  models.authenticationStore = AuthenticationStore.create({}, models);
}

export { AuthenticationStore, registerModels };

// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import { types } from 'mobx-state-tree';
import _ from 'lodash';
import AuthFlow from './AuthFlow';

const AppClient = types.model('AppClient', {
  id: types.identifier,
  rev: 0,
  name: types.string,

  // Client application secret. This will be available only at the time of creating app client
  // or when regenerating client secret. It is not retrievable by any GET calls
  secret: types.optional(types.string, ''),

  callbackUrls: types.optional(types.array(types.string), []),
  logoutUrls: types.optional(types.array(types.string), []),

  allowedOAuthFlows: types.optional(types.array(AuthFlow), []),

  createdAt: types.optional(types.string, ''),
  updatedAt: types.optional(types.string, ''),
}).views(self => ({
  get isActive() {
    // If not auth flows are allowed for this client then this client is treated as inactive
    return !_.isEmpty(self.allowedOAuthFlows);
  },
}));
export default AppClient;

import _ from 'lodash';
import React from 'react';
import { observer } from 'mobx-react';

// expected props
// - field (via props), this is the mobx form field object
const ErrorPointer = observer(({ field }) => {
  const { error = '' } = field;
  const hasError = !_.isEmpty(error); // IMPORTANT do NOT use field.hasError

  if (!hasError) return null;
  return (
    <div className="ui pointing basic label">{error}</div>
  );
});

export default ErrorPointer;

// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import _ from 'lodash';
import { observable } from 'mobx';

import KeyValuePairs from '../models/KeyValuePairs';

export interface CustomErrorAttributes {
  code: string,
  requestId: string,
  status: number // http status
  root: Partial<Error> & Partial<CustomErrorAttributes>
}

/**
 * Converts the given Map object to an array of values from the map
 */
function mapToArray<T>(map: Map<any, T>) {
  const result: T[] = [];
  // converting map to result array
  map.forEach((value: T) => result.push(value));
  return result;
}

/**
 * Converts a csv string into array with only non-empty string elements
 * @param csvString
 * @return string[] array of non-empty string elements
 */
function csvToNonEmptyStringArr(csvString: string): string[] {
  // split csv string and trim each element
  const urlsArr = _.map(_.split(csvString, ','), _.trim);
  // filter out any empty strings
  const callbackUrlsArr = _.filter(urlsArr, _.negate(_.isEmpty));
  return callbackUrlsArr;
}

function parseError(err: Partial<Error> & Partial<CustomErrorAttributes>): Partial<Error> & Partial<CustomErrorAttributes> {
  const message = _.get(err, 'message', 'Something went wrong');
  const code = _.get(err, 'code');
  const status = _.get(err, 'status');
  const requestId = _.get(err, 'requestId');
  const error: Error & Partial<CustomErrorAttributes> = new Error(message);

  error.code = code;
  error.requestId = requestId;
  error.root = err;
  error.status = status;

  return error;
}

function swallowError(promise: Promise<any>, fn = (_err?: any): any => ({})) {
  try {
    return Promise.resolve()
      .then(() => promise)
      .catch(err => fn(err));
  } catch (err) {
    return fn(err);
  }
}

const storage = observable({
  clear(): void {
    try {
      if (localStorage) return localStorage.clear();
      return window.localStorage.clear();
    } catch (err) {
      console.log(err);
      try {
        if (sessionStorage) return sessionStorage.clear();
        return window.sessionStorage.clear();
      } catch (error) {
        // if we get here, it means no support for localStorage nor sessionStorage, which is a problem
        console.log(error);
      }
    }
  },

  getItem(key: string): any {
    try {
      if (localStorage) return localStorage.getItem(key);
      return window.localStorage.getItem(key);
    } catch (err) {
      console.log(err);
      try {
        if (sessionStorage) return sessionStorage.getItem(key);
        return window.sessionStorage.getItem(key);
      } catch (error) {
        // if we get here, it means no support for localStorage nor sessionStorage, which is a problem
        console.log(error);
      }
    }
  },

  setItem(key: string, value: any): any {
    try {
      if (localStorage) return localStorage.setItem(key, value);
      return window.localStorage.setItem(key, value);
    } catch (err) {
      console.log(err);
      try {
        if (sessionStorage) return sessionStorage.setItem(key, value);
        return window.sessionStorage.setItem(key, value);
      } catch (error) {
        // if we get here, it means no support for localStorage nor sessionStorage, which is a problem
        console.log(error);
      }
    }
  },

  removeItem(key: string): any {
    try {
      if (localStorage) return localStorage.removeItem(key);
      return window.localStorage.removeItem(key);
    } catch (err) {
      console.log(err);
      try {
        if (sessionStorage) return sessionStorage.removeItem(key);
        return window.sessionStorage.removeItem(key);
      } catch (error) {
        // if we get here, it means no support for localStorage nor sessionStorage, which is a problem
        console.log(error);
      }
    }
  },
});

// a promise friendly delay function
function delay(seconds: number) {
  return new Promise(resolve => {
    _.delay(resolve, seconds * 1000);
  });
}

function getQueryParam(location: string, key: string) {
  const queryParams = (new URL(location)).searchParams;
  return queryParams.get(key);
}

function addQueryParams(location: string, params: KeyValuePairs) {
  const url = new URL(location);
  const queryParams = url.searchParams;

  const keys = _.keys(params);
  _.forEach(keys, key => {
    queryParams.append(key, params[key]);
  });

  let newUrl = url.origin + url.pathname;

  if (queryParams.toString()) {
    newUrl += `?${queryParams.toString()}`;
  }


  newUrl += url.hash;
  return newUrl;
}

function removeQueryParams(location: string, keys: ArrayLike<string>) {
  const url = new URL(location);
  const queryParams = url.searchParams;

  _.forEach(keys, key => {
    queryParams.delete(key);
  });

  let newUrl = url.origin + url.pathname;

  if (queryParams.toString()) {
    newUrl += `?${queryParams.toString()}`;
  }

  newUrl += url.hash;
  return newUrl;
}

function getFragmentParam(location: string, key: string | number) {
  const fragmentParams = new URL(location).hash;
  const hashKeyValues: KeyValuePairs = {};
  const params = fragmentParams.substring(1).split('&');
  if (params) {
    params.forEach(param => {
      const keyValueArr = param.split('=');
      const currentKey = keyValueArr[0];
      const value = keyValueArr[1];
      if (value) {
        hashKeyValues[currentKey] = value;
      }
    });
  }
  return hashKeyValues[key];
}

function removeFragmentParams(location: string, keyNamesToRemove: ArrayLike<string> | null | undefined) {
  const url = new URL(location);
  const fragmentParams = url.hash;
  let hashStr = '#';
  const params = fragmentParams.substring(1).split('&');
  if (params) {
    params.forEach(param => {
      const keyValueArr = param.split('=');
      const currentKey = keyValueArr[0];
      const value = keyValueArr[1];
      // Do not include the currentKey if it is the one specified in the array of keyNamesToRemove
      if (value && _.indexOf(keyNamesToRemove, currentKey) < 0) {
        hashStr = `${currentKey}${currentKey}=${value}`;
      }
    });
  }
  return `${url.protocol}//${url.host}${url.search}${hashStr === '#' ? '' : hashStr}`;
}

export {
  mapToArray,
  csvToNonEmptyStringArr,
  parseError,
  swallowError,
  storage,
  delay,
  getQueryParam,
  removeQueryParams,
  addQueryParams,
  getFragmentParam,
  removeFragmentParams,
};

// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';
import MobxReactForm from 'mobx-react-form';

const formPlugins = Object.freeze({
  dvr: dvr(validatorjs),
});

const formOptions = Object.freeze({
  showErrorsOnReset: false,
});


function createForm(fields, pluginsParam, optionsParam) {
  const plugins = pluginsParam || formPlugins;
  const options = optionsParam || formOptions;
  return new MobxReactForm({ fields: fields }, { plugins, options });
}

export {
  formPlugins,
  formOptions,
  createForm,
};

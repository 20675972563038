import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';

import 'typeface-lato';
import './css/basscss-important.css';
import './css/semantic.min.css';
import './css/animate.css';
import 'toastr/build/toastr.css';
import './css/index.css';
import { Message, Icon, Container } from 'semantic-ui-react';

import initializeAmplify from './init-amplify';
import { initializeContextModels } from './contextModels';
import App from './App';

initializeAmplify();

// Disabling service worker
serviceWorker.unregister();

// Enable mobx strict mode, changes to state must be contained in actions
configure({ enforceActions: 'always' });
// setLivelynessChecking('error');

const contextModels = initializeContextModels();

// Render the main App
function renderApp() {
  ReactDOM.render(
    // App below accepts props via react router injection + mobx react injection
    // since we don't pass those props explicitly here TS things they are missing and hence it complains
    // TODO: figure out a better solution. For now, ignoring the TS error here
    // See some discussion at https://github.com/mobxjs/mobx-react/issues/256
    <Provider {...contextModels}>
      <BrowserRouter>
        {/*
          // @ts-ignore */}
        <App />
      </BrowserRouter>
    </Provider>
    , document.getElementById('root'));
}

// Render a progress message
function renderProgress() {
  ReactDOM.render(
    <Container text className="pt4">
      <Message icon>
        <Icon name="circle notched" loading />
        <Message.Content>
          <Message.Header>Just one second</Message.Header>
          Loading application, please wait!
        </Message.Content>
      </Message>
    </Container>
    , document.getElementById('root'));
}

// Render an error message
function renderError(err: any) {
  const error = _.get(err, 'message', 'Unknown error');
  ReactDOM.render(
    <Container text className="pt4">
      <Message negative>
        <Message.Header>We have a problem</Message.Header>
        <p>{error}</p>
        <p>See if refreshing the browser will resolve your issue</p>
      </Message>
    </Container>
    , document.getElementById('root'));
}

renderProgress();

// Trigger the app startup sequence
(async () => {
  try {
    await contextModels.appStore.start();
    renderApp();
  } catch (err) {
    console.log(err);
    // TODO - check if the code = tokenExpired, then
    // - render a notification error
    // - call cleaner cleanup, this is IMPORTANT
    // - render the app and skip the rest of the renderError logic
    // - doing the above logic will help us have a smooth user experience
    //   when the token has expired. NOTE: this might not be applicable for the
    //   cases where the app requires midway before anything is displayed to the user
    renderError(err);
    try {
      contextModels.cleaner.cleanup();
    } catch (error) {
      // ignore
      console.log(error);
    }
  }
})();

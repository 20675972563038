// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import {
  applySnapshot, Instance, SnapshotIn, types,
} from 'mobx-state-tree';
import { BaseStore } from '../BaseStore';
import { ContextModels } from '../../contextModels';
import { mapToArray } from '../../helpers/utils';
import AppClient from './AppClient';
import {
  addAppClient, deleteAppClient, getAppClients, resetAppClientSecret, updateAppClient,
} from '../../helpers/api';

const AppClientStore = BaseStore
  .named('AppClientStore')
  .props({
    // Map containing "id" of the AppClient as Key and AppClient model object as value
    appClients: types.optional(types.map(AppClient), {}),
  })
  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;
    return {
      async doLoad() {
        const appClients = await getAppClients();
        self.runInAction(() => {
          const map = {};
          appClients.forEach((appClient: SnapshotIn<typeof AppClient>) => {
            const appClientModel = AppClient.create(appClient);
            // @ts-ignore
            map[appClientModel.id] = appClientModel;
          });
          self.appClients.replace(map);
        });
      },
      async addAppClient(appClient: Omit<SnapshotIn<typeof AppClient>, 'id'>) {
        const addedAppClient = await addAppClient(appClient);
        self.runInAction(() => {
          // The following will automatically put addedAppClient with id (because it's of type 'type.identifier') as Key
          // and addedAppClient as Value
          self.appClients.put(AppClient.create(addedAppClient));
        });
        return addedAppClient;
      },
      async updateAppClient(id: string, appClient: SnapshotIn<typeof AppClient>) {
        // @ts-ignore
        const previousAppClient = self.mustGetAppClient(id);
        const updatedAppClient = await updateAppClient(id, previousAppClient.rev, appClient);
        self.runInAction(() => {
          // @ts-ignore
          applySnapshot(previousAppClient, updatedAppClient);
        });
        return updatedAppClient;
      },
      async resetAppClientSecret(id: string) {
        // @ts-ignore
        const previousAppClient = self.mustGetAppClient(id);
        const updatedAppClient = await resetAppClientSecret(id, previousAppClient.rev);
        self.runInAction(() => {
          // @ts-ignore
          applySnapshot(previousAppClient, updatedAppClient);
        });
        return updatedAppClient;
      },
      async deleteAppClient(id: string) {
        await deleteAppClient(id);
        self.runInAction(() => {
          self.appClients.delete(id);
        });
      },
      cleanup: () => {
        superCleanup();
      },
    };
  })
  .views(self => ({

    get empty() {
      return self.appClients.size === 0;
    },
    get list() {
      return mapToArray<Instance<typeof AppClient>>(self.appClients);
    },
    getAppClient(id: string): Instance<typeof AppClient> | undefined {
      return self.appClients.get(id);
    },
    mustGetAppClient(id: string): Instance<typeof AppClient> {
      const appClient = self.appClients.get(id);
      if (!appClient) {
        throw new Error(`No application client with id = ${id} found`);
      }
      return appClient;
    },
  }));

function registerModels(models: ContextModels) {
  models.appClientStore = AppClientStore.create({}, models);
}

export { AppClientStore, registerModels };

import _ from 'lodash';
import React from "react";
import {Instance} from "mobx-state-tree";
import Idp from "../../models/idp/Idp";
import {Button, Header, Label, Modal, SemanticCOLORS} from "semantic-ui-react";
import {gotoFn} from "../../helpers/routing";
import {RouteComponentProps, withRouter} from "react-router";
import {inject, observer} from "mobx-react";
import {displayError, displaySuccess} from "../../helpers/notification";
import {IdpStore} from "../../models/idp/IdpStore";
import IdpListItemCardState, {IdpStatusToggleActions} from "../../models/component-states/IdpListItemCardState";

interface OwnProps {
  // Declare regular properties here
  idp: Instance<typeof Idp>,
  pos: number,
  idpListItemCardState: Instance<typeof IdpListItemCardState>
}

interface PathParams {
  // Declare any params coming from the router for example if you have "/some/url/:someParam" route
  // then declare "someParam" here
}

type ComponentProps =
  { idpStore: Instance<typeof IdpStore> } // Properties being injected by Mobx React
  & RouteComponentProps<PathParams> // Path params coming from the route url
  & OwnProps // Component's own regular properties that should be passed by the parent component

/**
 * Component for listing configured identity providers
 */
class IdpListItemCard extends React.Component<ComponentProps> {

  render() {
    const idp = this.getIdp();
    return (
      // The custom attribute "data-id" here is used for conveying the id of the virtualDatabase being clicked in the "handleVirtualDatabaseClick" handler
      <React.Fragment>
        <div className="flex">
          <div className="flex-auto">
            <div className="flex">
              {this.renderIndexLabel()}
              <Header as="h2" color="grey" className="mt0">
                {idp.name} <span className="pl2 fs-9 breakout">{idp.entityId}</span>
                <div>{this.renderStatus(idp)} <span className="breakout">{this.renderIdpType(idp)}</span></div>
              </Header>
              <div className="ml-auto">{this.renderActionButtons(idp)}</div>
            </div>
            <div className="ml3 mb2 mt2 breakout">{idp.desc}</div>
          </div>
        </div>
        {this.renderConfirmDeleteDialog()}
        {this.renderConfirmToggleStatusDialog()}
      </React.Fragment>
    );
  }

  protected getIdp() {
    return this.props.idp;
  }

  protected getStore() {
    return this.props.idpStore;
  }

  protected renderIdpType(idp: Instance<typeof Idp>) {
    const colorMappings = {
      GCKey: 'teal',
      SecureKey: 'blue',
      OtherSAML2Idp: 'violet'
    };
    // @ts-ignore
    return <Label color={colorMappings[idp.type]}>{idp.type}</Label>;
  }

  protected renderStatus(idp: Instance<typeof Idp>) {
    const isActive = _.toLower(idp.status) === 'active';
    return <Label color={isActive ? 'green' : 'red'}>{idp.status}</Label>;
  }

  protected renderIndexLabel() {
    const pos = this.props.pos;
    return <Label size="mini" ribbon color="blue" className="line-height-20-px">{pos}</Label>;
  }

  protected renderActionButtons(idp: Instance<typeof Idp>) {
    // @ts-ignore
    const isActive = idp.isActive;
    const activateColor: SemanticCOLORS = 'green';
    const deactivateColor: SemanticCOLORS = 'orange';

    let toggleButtonAttributes = isActive ? {
      content: 'Deactivate',
      color: deactivateColor,
      icon: 'close',
      toggleAction: IdpStatusToggleActions.deactivate
    } : {
      content: 'Activate',
      color: activateColor,
      icon: 'check',
      toggleAction: IdpStatusToggleActions.activate
    };
    return (
      <React.Fragment>
        <Button content='Edit' icon='edit' primary onClick={this.handleEditClick} size='mini'/>
        <Button content={toggleButtonAttributes.content}
                color={toggleButtonAttributes.color}
                icon={toggleButtonAttributes.icon}
                onClick={() => this.openToggleStatusDialog(toggleButtonAttributes.toggleAction)} size='mini'/>
        <Button content='Delete' color='red' icon='trash' onClick={this.openConfirmDeleteDialog} size='mini'/>
      </React.Fragment>
    );
  }

  protected renderConfirmDeleteDialog() {
    const shouldShowDialog = this.props.idpListItemCardState.shouldShowDeleteDialog;
    const progress = this.props.idpListItemCardState.deleteInProgress;
    const id = this.getIdp().entityId;
    return (
      <Modal open={shouldShowDialog} size="tiny" onClose={this.hideDeleteDialog} closeOnDimmerClick={!progress}>
        <Header content="Delete Identity Provider"/>
        <Modal.Content>
          <div>
            Are you sure you want to delete identity provider <strong>{id}</strong> ? This cannot be undone.
            If you change your mind later, you will have to configure the identity provider again.
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancel" disabled={progress} onClick={this.hideDeleteDialog}/>
          <Button content="Delete" icon="remove" loading={progress} disabled={progress} color="red"
                  onClick={this.handleDeleteIdp}/>
        </Modal.Actions>
      </Modal>
    );
  }

  protected renderConfirmToggleStatusDialog() {
    const shouldShowDialog = this.props.idpListItemCardState.shouldShowToggleStatusDialog;
    const progress = this.props.idpListItemCardState.toggleStatusInProgress;
    const toggleAction = this.props.idpListItemCardState.toggleAction;

    const id = this.getIdp().entityId;

    const activateColor: SemanticCOLORS = 'green';
    const deactivateColor: SemanticCOLORS = 'orange';

    let dialogInfo;
    if (toggleAction === IdpStatusToggleActions.activate) {
      dialogInfo = {
        title: 'Activate Identity Provider',
        message: <React.Fragment><div>Are you sure you want to activate the identity provider <strong>{id}</strong> ? </div><div>Activating the identity provider (IdP) will potentially allow users from the IdP to login to any configured client application.</div></React.Fragment>,
        confirmButtonLabel: 'Yes, activate',
        color: activateColor,
        icon: 'check',
      };
    } else {
      dialogInfo = {
        title: 'Deactivate Identity Provider',
        message: <React.Fragment><div>Are you sure you want to deactivate the identity provider <strong>{id}</strong> ? </div><div>Deactivating the identity provider (IdP) will prevent all users from the IdP to login to any configured client application.</div></React.Fragment>,
        confirmButtonLabel: 'Yes, deactivate',
        color: deactivateColor,
        icon: 'close',
      };
    }

    return (
      <Modal open={shouldShowDialog} size="tiny" onClose={this.hideToggleStatusDialog} closeOnDimmerClick={!progress}>
        <Header content={dialogInfo.title}/>
        <Modal.Content>{dialogInfo.message}</Modal.Content>
        <Modal.Actions>
          <Button content="Cancel" disabled={progress} onClick={this.hideToggleStatusDialog}/>
          <Button content={dialogInfo.confirmButtonLabel} icon={dialogInfo.icon} loading={progress} disabled={progress}
                  color={dialogInfo.color}
                  onClick={this.handleToggleIdpStatus}/>
        </Modal.Actions>
      </Modal>
    );
  }

  protected openConfirmDeleteDialog = () => {
    this.props.idpListItemCardState.setShouldShowDeleteDialog(true);
  };

  protected openToggleStatusDialog = (toggleAction: IdpStatusToggleActions) => {
    this.props.idpListItemCardState.setShouldShowToggleStatusDialog(true);
    this.props.idpListItemCardState.setToggleAction(toggleAction);
  };

  protected hideDeleteDialog = () => {
    const progress = this.props.idpListItemCardState.deleteInProgress;
    if (progress) return;
    this.props.idpListItemCardState.setShouldShowDeleteDialog(false);
  };
  protected hideToggleStatusDialog = () => {
    const progress = this.props.idpListItemCardState.toggleStatusInProgress;
    if (progress) return;
    this.props.idpListItemCardState.setShouldShowToggleStatusDialog(false);
  };

  protected handleDeleteIdp = async () => {
    const entityId = this.getIdp().entityId;
    const store = this.getStore();
    this.props.idpListItemCardState.setDeleteInProgress(true);
    try {
      await store.deleteIdp(entityId);

      this.props.idpListItemCardState.setDeleteInProgress(false);
      this.props.idpListItemCardState.setShouldShowDeleteDialog(false);
      displaySuccess('The identity provider is deleted successfully','Deleted!');
    } catch (error) {
      this.props.idpListItemCardState.setDeleteInProgress(false);
      this.props.idpListItemCardState.setShouldShowDeleteDialog(false);
      displayError(error);
    }
  };

  protected handleToggleIdpStatus = async () => {
    const entityId = this.getIdp().entityId;
    const store = this.getStore();
    this.props.idpListItemCardState.setToggleStatusInProgress(true);
    try {
      const idp = this.getIdp();
      const isActive = idp.isActive;
      if (isActive) {
        await store.deactivateIdp(entityId);
      } else {
        await store.activateIdp(entityId);
      }
      this.props.idpListItemCardState.setToggleStatusInProgress(false);
      this.props.idpListItemCardState.setShouldShowToggleStatusDialog(false);
      displaySuccess('The identity provider is updated successfully','Updated!');
    } catch (error) {
      this.props.idpListItemCardState.setToggleStatusInProgress(false);
      this.props.idpListItemCardState.setShouldShowToggleStatusDialog(false);
      displayError(error);
    }
  };

  protected handleEditClick = () => {
    const id = this.getIdp().entityId;
    const goto = gotoFn(this);
    goto(`/idps/edit/${encodeURIComponent(id)}`);
  };
};

export default inject('idpStore')(withRouter(observer(IdpListItemCard)));

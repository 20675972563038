// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import { Instance, types } from 'mobx-state-tree';
import AppClient from '../app-client/AppClient';

const AppClientListItemCardState = types.model('AppClientListItemCardState', {
  shouldShowDeleteDialog: false,
  shouldShowResetSecretDialog: false,
  deleteInProgress: false,
  resetSecretInProgress: false,

  resetAppClient: types.maybe(AppClient),

}).actions(self => ({
  setShouldShowDeleteDialog(shouldShowDeleteDialog: boolean) {
    self.shouldShowDeleteDialog = shouldShowDeleteDialog;
  },
  setShouldShowResetSecretDialog(shouldShowResetSecretDialog: boolean) {
    self.shouldShowResetSecretDialog = shouldShowResetSecretDialog;
  },
  setDeleteInProgress(deleteInProgress: boolean) {
    self.deleteInProgress = deleteInProgress;
  },
  setResetSecretInProgress(resetSecretInProgress: boolean) {
    self.resetSecretInProgress = resetSecretInProgress;
  },
  setResetAppClient(resetAppClient: Instance<typeof AppClient> | undefined) {
    self.resetAppClient = resetAppClient;
  },
}));

export default AppClientListItemCardState;

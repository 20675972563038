import React from "react";
import {Button, Container, Header, Icon, Label, Modal, Segment} from "semantic-ui-react";
import {RouteComponentProps, withRouter} from "react-router";
import {inject, observer} from "mobx-react";
import {Instance, SnapshotIn} from "mobx-state-tree";

import {csvToNonEmptyStringArr, swallowError} from "../../helpers/utils";
import {isStoreError, isStoreLoading, isStoreReady} from "../../models/BaseStore";
import ErrorBox from "../helpers/ErrorBox";
import BasicProgressPlaceholder from "../helpers/BasicProgressPlaceholder";
import {AppClientStore} from "../../models/app-client/AppClientStore";
import Input from '../helpers/fields/Input';
import Form from '../helpers/Form';
import {gotoFn} from "../../helpers/routing";
import {displayError} from "../../helpers/notification";

import {getForm as getAddAppClientForm} from "../../models/forms/AppClientFormModel"
import _ from "lodash";
import AddAppClientState from "../../models/component-states/AddAppClientState";
import AppClient from "../../models/app-client/AppClient";
import DropDown from "../helpers/fields/DropDown";
import {getAuthFlowOptions} from "../../models/app-client/AuthFlow";

interface OwnProps {
  // Declare regular properties here
}

interface PathParams {
  // Declare any params coming from the router for example if you have "/some/url/:someParam" route
  // then declare "someParam" here
}

type ComponentProps =
  { appClientStore: Instance<typeof AppClientStore> } // Properties being injected by Mobx React
  & RouteComponentProps<PathParams> // Path params coming from the route url
  & OwnProps // Component's own regular properties that should be passed by the parent component

/**
 * Component for listing configured identity providers
 */
class AddAppClient extends React.Component<ComponentProps> {
  private readonly addAppClientForm: any;
  private addAppClientState: Instance<typeof AddAppClientState>;

  constructor(props: ComponentProps) {
    super(props);
    this.addAppClientForm = getAddAppClientForm();
    this.addAppClientState = AddAppClientState.create();
  }

  getStore() {
    return this.props.appClientStore;
  }

  componentDidMount() {
    const store = this.getStore();
    swallowError(store.load());
  }

  componentWillUnmount(): void {
    this.addAppClientState.setAppClientAdded(undefined);
  }

  render() {
    const store = this.getStore();
    let content = null;

    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} className="p0"/>;
    } else if (isStoreLoading(store)) {
      content = <BasicProgressPlaceholder segmentCount={3}/>;
    } else if (isStoreReady(store)) {
      content = this.renderMain();
    } else {
      content = null;
    }

    return (
      <Container className="mt3 mb4">
        {this.renderTitle()}
        {content}
      </Container>
    );
  }

  renderTitle() {
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="computer" className="align-top"/>
          <Header.Content className="left-align">Configure Application Client</Header.Content>
        </Header>
      </div>
    );
  }

  renderMain() {
    const form = this.addAppClientForm;
    const authFlowOptions = getAuthFlowOptions();

    const nameField = form.$('name');
    const callbackUrlsField = form.$('callbackUrls');
    const logoutUrlsField = form.$('logoutUrls');
    const authFlowsField = form.$('allowedOAuthFlows');

    return <Segment clearing className="p3">
      <Form form={form} onCancel={this.handleCancel} onSuccess={this.handleFormSubmission}
            onError={this.handleFormError}>
        {({processing, onCancel}: { processing: boolean, onCancel: any }) =>
          <React.Fragment>

            <Input field={nameField} disabled={processing}/>
            <Input field={callbackUrlsField} disabled={processing}/>
            <Input field={logoutUrlsField} disabled={processing}/>
            <DropDown options={authFlowOptions} field={authFlowsField} selection={true} fluid={true} clearable={true} multiple={true} search={true} disabled={processing}/>

            <div className="mt3">
              <Button floated="right" color="blue" icon disabled={processing} className="ml2" type="submit">Add
                Application Client</Button>
              <Button floated="right" disabled={processing} onClick={onCancel}>Cancel</Button>
            </div>
          </React.Fragment>
        }
      </Form>
      {this.renderConfirmCopySecretDialog()}
    </Segment>
  }

  protected renderConfirmCopySecretDialog() {
    const appClientAdded = this.addAppClientState.appClientAdded;
    const shouldShowDialog = !!appClientAdded;
    const id = appClientAdded?appClientAdded.id:'';
    const name = appClientAdded?appClientAdded.name:'';
    const secret = appClientAdded ? appClientAdded.secret : '';

    return (
      <Modal open={shouldShowDialog} size="large" onClose={this.hideConfirmCopySecretDialog} closeOnDimmerClick={false}>
        <Modal.Header content="Application Client Added Successfully"/>
        <Modal.Content>
          <Modal.Description>
            <div>
              The application client <strong>{name}</strong> with id <strong>{id}</strong> is added successfully.
              The application client secret is
            </div>
            <p>&nbsp;</p>
            <Label>
              <Container text style={{wordWrap: 'break-word'}}>{secret}</Container>
            </Label>
            <p>&nbsp;</p>
            <div>
              The app client secret is displayed here only once. Please save it now.
              If you loose app client secret you will have to reset it.
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Ok, I copied the secret. Close now." onClick={this.hideConfirmCopySecretDialog}/>
        </Modal.Actions>
      </Modal>
    );
  }
  protected hideConfirmCopySecretDialog = () => {
    // Clear the added application client from the addAppClientState to close the dialog box
    this.addAppClientState.setAppClientAdded(undefined);
    const goto = gotoFn(this);
    goto('/app-clients');
  };
  protected showSuccessDialog = (appClient: SnapshotIn<typeof AppClient>) => {
    this.addAppClientState.setAppClientAdded(AppClient.create(appClient));
  };

  handleCancel = () => {
    const goto = gotoFn(this);
    goto('/app-clients');
  };

  handleFormError = (_form: any, _errors: any) => {
    // We don't need to do anything here
  };

  handleFormSubmission = async (form: any) => {
    const {name, callbackUrls, logoutUrls, allowedOAuthFlows} = form.values();

    const store = this.getStore();
    try {
      const addedAppClient = await store.addAppClient({
        name,
        callbackUrls: csvToNonEmptyStringArr(callbackUrls),
        logoutUrls: csvToNonEmptyStringArr(logoutUrls),
        allowedOAuthFlows: _.isEmpty(allowedOAuthFlows) ? [] : allowedOAuthFlows, // Make sure to send undefined for allowedOAuthFlows when it's empty
      });

      this.showSuccessDialog(addedAppClient);

      form.clear();
    } catch (error) {
      displayError(error);
    }
  }
}

export default inject('appClientStore')(withRouter(observer(AddAppClient)));

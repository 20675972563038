// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import _ from 'lodash';
import { runInAction } from 'mobx';

import { forgetAccessToken } from '../helpers/api';
import { ContextModels } from '../contextModels';

// An object that captures all the clean up logic when the app is done or no jwt token
// is found.
class Cleaner {
  private readonly contextModels: ContextModels;

  constructor(contextModels: ContextModels) {
    this.contextModels = contextModels;
  }

  cleanup() {
    const { disposers, intervalIds } = this.contextModels;

    // it is important that we start with cleaning the disposers, otherwise snapshots events will be fired
    // for cleaned stores
    let keys = _.keys(disposers);
    _.forEach(keys, key => {
      const fn = disposers[key];
      if (_.isFunction(fn)) {
        fn();
      }
      delete disposers[key];
    });

    keys = _.keys(intervalIds);
    _.forEach(keys, key => {
      const id = intervalIds[key];
      if (!_.isNil(id)) {
        clearInterval(id);
      }
      delete intervalIds[key];
    });

    runInAction(() => {
      forgetAccessToken();

      _.forEach(this.contextModels, (obj, _key) => {
        if (obj === this) return; // we don't want to end up in an infinite loop
        if (_.isFunction(obj.cleanup)) {
          // console.log(`Cleaner.cleanup() : calling ${key}.clear()`);
          obj.cleanup();
        }
      });
    });
  }
}

function registerModels(models: ContextModels) {
  models.cleaner = new Cleaner(models);
}
export { Cleaner, registerModels };

// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import { createForm } from '../../helpers/form';

const formFields = {
  name: {
    label: 'Identity Provider Name',
    extra: {
      explain: 'Name of the identity provider. This is mainly used for display purpose and easier identification. '
        + 'This is a required field and the number of characters must be between 3 and 100. ',
    },
    placeholder: 'Enter identity provider name.',
    rules: 'string|required|between:3,100|regex:/^[a-zA-Z0-9_-]+$/',
  },
  desc: {
    label: 'Identity Provider Description',
    extra: {
      explain: 'The identity provider description to help other admin users. '
        + 'The description can have a maximum of 2048 characters.',
    },
    placeholder: 'Enter some description for the identity provider',
    rules: 'max:2048',
  },
  xmlMetadata: {
    label: 'SAML Identity Provider XML Metadata',
    extra: {
      explain: 'XML metadata about the identity provider. This is a required field.',
    },
    placeholder: 'Enter XML metadata about the identity provider',
    rules: 'string|required',
  },
  type: {
    label: 'Identity Provider Type',
    extra: {
      explain: 'Type of identity provider. This is a required field.',
    },
    placeholder: 'Select type of the identity provider',
    rules: 'string',
  },
};

function getFormFields() {
  return formFields;
}

function getForm(fields = formFields) {
  return createForm(fields);
}

export { getForm, getFormFields };

// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import { types } from 'mobx-state-tree';
import { Startup } from './Startup';
import { ContextModels } from '../contextModels';

const AppStore = types.model('AppStore', {
  userAuthenticated: types.optional(types.boolean, false),
  startup: types.optional(Startup, {}),
})
  .actions(self => ({
    start: async () => {
      await self.startup.load();
    },
    setUserAuthenticated(flag: boolean) {
      self.userAuthenticated = flag;
    },

    // this method is called by the Cleaner
    cleanup() {
      // TODO: TS does not recognize the actions being added dynamically in the actions method
      // ignoring typescript error for now
      // See some discussion regarding this at
      // https://github.com/mobxjs/mobx-state-tree/issues/1307
      // @ts-ignore
      self.setUserAuthenticated(false);
    },
  }));

function registerModels(models: ContextModels) {
  models.appStore = AppStore.create({}, models);
}

export { AppStore, registerModels };

import _ from 'lodash';
import React from 'react';
import { Segment, Placeholder, Divider } from 'semantic-ui-react';

const BasicProgressPlaceholder = ({segmentCount = 1, className}: { segmentCount?: number, className?: string }) => {
  const segment = (index: string | number | undefined) =>
    <Segment key={index} className="p3 mb2">
      <Placeholder fluid>
        <Placeholder.Header>
          <Placeholder.Line length="full"/>
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line length="short"/>
        </Placeholder.Paragraph>
      </Placeholder>
      <Divider className="mt3"/>
      <Placeholder fluid>
        <Placeholder.Line length="full"/>
      </Placeholder>
    </Segment>;

  return <div className={className}>{_.map(_.times(segmentCount, String), (index) => segment(index))}</div>;
};

export default BasicProgressPlaceholder;

// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import { types } from 'mobx-state-tree';

const LoginState = types.model('LoginState', {
  username: '',
  password: '',
  usernameError: '',
  passwordError: '',
  loading: false,
}).actions(self => ({
  setUserName(username: string) {
    self.username = username;
  },
  setPassword(password: string) {
    self.password = password;
  },
  setUserNameError(usernameError: string) {
    self.usernameError = usernameError;
  },
  setPasswordError(passwordError: string) {
    self.passwordError = passwordError;
  },
  setLoading(loading: boolean) {
    self.loading = loading;
  },
}));

export default LoginState;

// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import { types } from 'mobx-state-tree';

enum IdpStatusToggleActions {
  activate = 'activate',
  deactivate = 'deactivate',
}

const IdpListItemCardState = types.model('IdpListItemCardState', {
  shouldShowDeleteDialog: false,
  shouldShowToggleStatusDialog: false,
  deleteInProgress: false,
  toggleStatusInProgress: false,
  toggleAction: IdpStatusToggleActions.deactivate,

}).actions(self => ({
  setShouldShowDeleteDialog(shouldShowDeleteDialog: boolean) {
    self.shouldShowDeleteDialog = shouldShowDeleteDialog;
  },
  setShouldShowToggleStatusDialog(shouldShowToggleStatusDialog: boolean) {
    self.shouldShowToggleStatusDialog = shouldShowToggleStatusDialog;
  },
  setToggleAction(action: IdpStatusToggleActions) {
    self.toggleAction = action;
  },
  setDeleteInProgress(deleteInProgress: boolean) {
    self.deleteInProgress = deleteInProgress;
  },
  setToggleStatusInProgress(toggleStatusInProgress: boolean) {
    self.toggleStatusInProgress = toggleStatusInProgress;
  },
}));

export { IdpStatusToggleActions };
export default IdpListItemCardState;

// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import { getEnv } from 'mobx-state-tree';
import _ from 'lodash';

import { setAccessToken } from '../helpers/api';
import { BaseStore } from './BaseStore';
import { displayError } from '../helpers/notification';

const Startup = BaseStore
  .named('Startup')
  .actions(self => ({
    doLoad: async (): Promise<any> => {
      // The logic:
      // - ask the authentication model to clear any expired tokens
      // - ask the authentication model for the id token
      //   - if not found, it is either expired or does not exists, in both cases, we will consider this a clean start
      //   - if found,
      //      - set the token on the api object
      //      - ask the userStore to load the user
      //      - if we get an error, display the error and clean everything, very much starting over
      //      - if we don't get an error, then
      //        - set appStore.setUserAuthenticated(true);
      //        - and we are done! yay!

      const { cleaner } = getEnv(self);
      const { currentUserStore } = getEnv(self);
      const { appStore } = getEnv(self);
      const { authenticationStore } = getEnv(self);

      const accessToken = await authenticationStore.getActiveAccessToken();
      if (_.isEmpty(accessToken)) {
        // either the token expired or this is clean start
        return cleaner.cleanup();
      }

      setAccessToken(accessToken);

      try {
        await currentUserStore.load();
      } catch (error) {
        displayError('Encountered a problem trying to get the user information of the logged in user. Try refreshing the page and logging in once again.', error);
        return cleaner.cleanup();
      }

      return self.runInAction(() => {
        const { user } = currentUserStore;
        appStore.setUserAuthenticated(!_.isNil(user));
      });
    },
  }));

// eslint-disable-next-line import/prefer-default-export
export { Startup };

// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import _ from 'lodash';

import { types } from 'mobx-state-tree';
import KeyValuePairs from '../KeyValuePairs';

enum AuthFlows {
  // TODO: Add support for authorization code grant flow
  // code = 'code',
  implicit = 'implicit',
  client_credentials = 'client_credentials',
}

const authFlowLabels: KeyValuePairs = {
  code: 'Authorization code grant',
  implicit: 'Implicit grant',
  client_credentials: 'Client credentials',
};

const AuthFlow = types.enumeration<AuthFlows>('AuthFlow', Object.values(AuthFlows));

function getAuthFlowOptions() {
  const authFlows = Object.values(AuthFlows);
  return _.map(authFlows, authFlowValue => ({
    key: authFlowValue,
    value: authFlowValue,
    text: authFlowLabels[authFlowValue],
  }));
}

function getAuthFlowLabel(authFlow: string) {
  return authFlowLabels[authFlow];
}

export { getAuthFlowOptions };
export { getAuthFlowLabel };
export default AuthFlow;

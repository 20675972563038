import _ from 'lodash';
import React from "react";
import {Button, Container, Header, Icon, Label, Segment} from "semantic-ui-react";
import {swallowError} from "../../helpers/utils";
import {inject, observer} from "mobx-react";
import {Instance} from "mobx-state-tree";
import {isStoreEmpty, isStoreError, isStoreLoading, isStoreNotEmpty, isStoreReady} from "../../models/BaseStore";
import BasicProgressPlaceholder from "../helpers/BasicProgressPlaceholder";
import ErrorBox from "../helpers/ErrorBox";
import {gotoFn} from "../../helpers/routing";
import AppClient from "../../models/app-client/AppClient";
import {RouteComponentProps, withRouter} from "react-router";
import {AppClientStore} from "../../models/app-client/AppClientStore";
import AppClientListItemCard from "./AppClientListItemCard";
import AppClientListItemCardState from "../../models/component-states/AppClientListItemCardState";

interface OwnProps {
  // Declare regular properties here
}
interface PathParams {
  // Declare any params coming from the router for example if you have "/some/url/:someParam" route
  // then declare "someParam" here
}

type ComponentProps =
  { appClientStore: Instance<typeof AppClientStore> } // Properties being injected by Mobx React
  & RouteComponentProps<PathParams> // Path params coming from the route url
  & OwnProps // Component's own regular properties that should be passed by the parent component

/**
 * Component for listing configured identity providers
 */
class ListAppClients extends React.Component<ComponentProps> {

  render() {
    const store = this.getStore();
    let content = null;

    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} className="p0"/>;
    } else if (isStoreLoading(store)) {
      content = <BasicProgressPlaceholder segmentCount={3}/>;
    } else if (isStoreReady(store) && isStoreEmpty(store)) {
      content = this.renderEmpty();
    } else if (isStoreReady(store) && isStoreNotEmpty(store)) {
      content = this.renderMain();
    } else {
      content = null;
    }

    return (
      <Container className="mt3 mb4">
        {this.renderTitle()}
        {content}
      </Container>
    );
  }

  getStore() {
    return this.props.appClientStore;
  }

  componentDidMount() {
    const store = this.getStore();
    swallowError(store.load());
  }

  handleAddAppClientClick = () => {
    const goto = gotoFn(this);
    goto('/app-clients/add');
  };

  renderTitle() {
    const renderCount = () => {
      const store = this.getStore();
      const showCount = isStoreReady(store) && isStoreNotEmpty(store);
      const list = store.list;
      return showCount && <Label circular size="medium">{list.length}</Label>
    };
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="computer" className="align-top"/>
          <Header.Content className="left-align">
            Configured Application Clients {renderCount()}
          </Header.Content>
        </Header>
        <Button color="blue" size="medium" basic onClick={this.handleAddAppClientClick}>
          Configure New Client Application
        </Button>
      </div>
    );
  }

  renderEmpty() {
    return (
      <Segment placeholder>
        <Header icon className="color-grey">
          <Icon name="computer"/>
          No client applications are configured yet
          <Header.Subheader>
            <Button color="blue" size="medium" basic onClick={this.handleAddAppClientClick}>
              Configure New Client Application
            </Button>
          </Header.Subheader>
        </Header>
      </Segment>
    );
  }

  renderMain() {
    const store = this.getStore();
    const list = store.list;
    return (
      <React.Fragment>
        {_.map(list, (appClient: Instance<typeof AppClient>, idx) => {
          return <Segment clearing={true} key={appClient.id} className={appClient.isActive ? 'primary' : 'secondary'}>
            {/*
              The AppClientListItemCard below receives appClientStore via Mobx injection.
              TS does not know about that and complains about missing appClientStore property
              TODO: figure out a better solution. For now, ignoring the TS error here
              See some discussion at https://github.com/mobxjs/mobx-react/issues/256
            */
            }
            {/*
              // @ts-ignore */}
            <AppClientListItemCard key={appClient.id} appClient={appClient} pos={idx + 1} appClientListItemCardState={AppClientListItemCardState.create()}/>
          </Segment>;
        })}
      </React.Fragment>
    );
  }
}

export default inject('appClientStore')(withRouter(observer(ListAppClients)));

import React from "react";
import {Button, Container, Header, Icon, Segment} from "semantic-ui-react";
import {RouteComponentProps, withRouter} from "react-router";
import {inject, observer} from "mobx-react";
import {Instance, SnapshotIn} from "mobx-state-tree";

import {swallowError} from "../../helpers/utils";
import {isStoreError, isStoreLoading, isStoreReady} from "../../models/BaseStore";
import ErrorBox from "../helpers/ErrorBox";
import BasicProgressPlaceholder from "../helpers/BasicProgressPlaceholder";
import {IdpStore} from "../../models/idp/IdpStore";
import Input from '../helpers/fields/Input';
import TextArea from '../helpers/fields/TextArea';
import DropDown from "../helpers/fields/DropDown";
import Form from '../helpers/Form';
import {gotoFn} from "../../helpers/routing";
import {displayError, displaySuccess} from "../../helpers/notification";
import Idp from "../../models/idp/Idp";
import {getIdpTypeOptions} from "../../models/idp/IdpType";

import {getForm as getEditIdpForm} from "../../models/forms/IdpFormModel"

interface OwnProps {
  // Declare regular properties here
}

interface PathParams {
  // Declare any params coming from the router for example if you have "/some/url/:someParam" route
  // then declare "someParam" here
  entityId: string
}

type ComponentProps =
  { idpStore: Instance<typeof IdpStore> } // Properties being injected by Mobx React
  & RouteComponentProps<PathParams> // Path params coming from the route url
  & OwnProps // Component's own regular properties that should be passed by the parent component

/**
 * Component for listing configured identity providers
 */
class EditIdp extends React.Component<ComponentProps> {
  private readonly editIdpForm: any;

  constructor(props: ComponentProps) {
    super(props);
    this.editIdpForm = getEditIdpForm();
  }

  componentDidMount() {
    const store = this.getStore();
    swallowError(store.load());
  }

  render() {
    const store = this.getStore();
    let content = null;

    if (isStoreError(store)) {
      content = <ErrorBox error={store.error} className="p0"/>;
    } else if (isStoreLoading(store)) {
      content = <BasicProgressPlaceholder segmentCount={3}/>;
    } else if (isStoreReady(store)) {
      content = this.renderMain();
    } else {
      content = null;
    }

    return (
      <Container className="mt3 mb4">
        {content}
      </Container>
    );
  }

  protected getStore() {
    return this.props.idpStore;
  }

  protected getEntityId() {
    return decodeURIComponent((this.props.match.params || {}).entityId);
  }

  protected getIdpBeingEdited(): Instance<typeof Idp> | undefined {
    const id = this.getEntityId();
    return this.getStore().getIdp(id);
  }

  protected renderTitle(idp: Instance<typeof Idp>) {
    return (
      <div className="mb3 flex">
        <Header as="h3" className="color-grey mt1 mb0 flex-auto">
          <Icon name="user secret" className="align-top"/>
          <Header.Content className="left-align">{idp.name}</Header.Content>
        </Header>
      </div>
    );
  }

  protected renderMain() {
    const id = this.getEntityId();
    const idpBeingEdited: Instance<typeof Idp> | undefined = this.getIdpBeingEdited();
    if (!idpBeingEdited) {
      return <ErrorBox error={this.noIdpConfiguredError(id)}/>;
    }

    const form = this.editIdpForm;

    const nameField = form.$('name');
    nameField.value = idpBeingEdited.name;

    const descField = form.$('desc');
    descField.value = idpBeingEdited.desc;

    const xmlMetadataField = form.$('xmlMetadata');
    xmlMetadataField.value = idpBeingEdited.xmlMetadata;

    const idpTypeField = form.$('type');
    idpTypeField.value = idpBeingEdited.type;

    const idpTypeOptions = getIdpTypeOptions();

    return <React.Fragment>
      {this.renderTitle(idpBeingEdited)}
      <Segment clearing className="p3">
        <Form form={form} onCancel={this.handleCancel} onSuccess={this.handleFormSubmission}
              onError={this.handleFormError}>
          {({processing, onCancel}: { processing: boolean, onCancel: any }) =>
            <React.Fragment>

              <Input field={nameField} disabled={processing}/>
              <TextArea field={descField} disabled={processing}/>
              <TextArea field={xmlMetadataField} disabled={processing}/>
              <DropDown options={idpTypeOptions} field={idpTypeField} selection={true} fluid={true} search={true}
                        disabled={processing}/>

              <div className="mt3">
                <Button floated="right" color="blue" icon disabled={processing} className="ml2" type="submit">Update Identity Provider</Button>
                <Button floated="right" disabled={processing} onClick={onCancel}>Cancel</Button>
              </div>
            </React.Fragment>
          }
        </Form>
      </Segment>
    </React.Fragment>
  }

  protected handleCancel = () => {
    const goto = gotoFn(this);
    goto('/idps');
  };

  protected handleFormError = (_form: any, _errors: any) => {
    // We don't need to do anything here
  };

  protected noIdpConfiguredError(entityId: string) {
    return `Cannot update the identity provider (IdP). No IdP with entityId ${entityId} is configured.`
  }

  protected handleFormSubmission = async (form: any) => {
    const idpToUpdate: SnapshotIn<typeof Idp> = form.values();
    const store = this.getStore();
    try {
      await store.updateIdp(this.getEntityId(), idpToUpdate);
      displaySuccess('The identity provider is updated successfully');
      form.clear();

      const goto = gotoFn(this);
      goto('/idps');
    } catch (error) {
      displayError(error);
    }
  }
}

export default inject('idpStore')(withRouter(observer(EditIdp)));

import _ from 'lodash';
import React from 'react';
import { observer } from 'mobx-react';
import { Dropdown as SemanticUiDropdown } from 'semantic-ui-react';

import Header from './Header';
import Description from './Description';
import ErrorPointer from './ErrorPointer';

// expected props
// - field (via props), this is the mobx form field object
// - options (via props), an array of [ {text, value}, {text, value}, ...]
// - onChange (via props), (optional) if provided, it will be given (value, field)
// - className (via props)
//
// The following props are to support existing React Semantic UI props:
// - selection (via props), default to false
// - fluid (via props), default to false
// - disabled (via props), default to false
// - clearable (via props), default to false
// - multiple (via props), default to false
// - search (via props), default to false
const DropDown = observer(({
  field,
  selection = false,
  fluid = false,
  disabled = false,
  clearable = false,
  multiple = false,
  search = false,
  className = 'mb4',
  options = [],
  onChange,
}) => {
  const { id, value, sync, placeholder, error = '', extra = {} } = field;
  const hasError = !_.isEmpty(error); // IMPORTANT do NOT use field.hasError
  const mergeOptions = [...((extra && extra.options) || []), ...options];
  const isDisabled = field.disabled || disabled;
  const disabledClass = isDisabled? 'disabled': '';
  const errorClass = hasError? 'error': '';
  const attrs = {
    id,
    value,
    onChange: (_e: any, data: any = {}) => {
      sync(data.value);
      field.validate({ showErrors: true });
      if (onChange) onChange(data.value, field);
    },
    placeholder,
    selection,
    clearable,
    multiple,
    search,
    fluid,
    disabled: isDisabled,
    error: hasError,
  };

  return (
    <div className={`${className} ${errorClass} ${disabledClass}`}>
      <Header field={field} />
      <Description field={field}/>
      <SemanticUiDropdown className="field" options={mergeOptions} {...attrs} />
      <ErrorPointer field={field}/>
    </div>
  );
});

export default DropDown;

// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import { types } from 'mobx-state-tree';

const User = types.model('User', {
  firstName: types.maybeNull(types.optional(types.string, '')),
  lastName: types.maybeNull(types.optional(types.string, '')),
  username: types.identifier,
  email: types.maybeNull(types.optional(types.string, '')),
})
  .views(self => ({
    get displayName() {
      return `${self.firstName} ${self.lastName}`;
    },
    get longDisplayName() {
      // @ts-ignore
      if (self.unknown) {
        return `${self.username}??`;
      }
      const fullName = `${self.firstName} ${self.lastName}`;
      if (self.email) {
        return `${fullName} (${self.email})`;
      }
      return fullName;
    },
    get unknown() {
      return (!self.firstName) && (!self.lastName);
    },
  }));

export { User };

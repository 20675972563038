// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import {
  applySnapshot, Instance, SnapshotIn, types,
} from 'mobx-state-tree';
import { BaseStore } from '../BaseStore';
import Idp from './Idp';
import { ContextModels } from '../../contextModels';
import { mapToArray } from '../../helpers/utils';
import {
  activateIdp, addIdp, deactivateIdp, deleteIdp, getIdps, updateIdp,
} from '../../helpers/api';

const IdpStore = BaseStore
  .named('IdpStore')
  .props({
    // Map containing "entityId" of the Idp as Key and Idp model object as value
    idps: types.optional(types.map(Idp), {}),
  })
  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;
    return {
      async doLoad() {
        const idps = await getIdps();
        self.runInAction(() => {
          const map = {};
          idps.forEach((idp: SnapshotIn<typeof Idp>) => {
            const idpModel = Idp.create(idp);
            // @ts-ignore
            map[idpModel.entityId] = idpModel;
          });
          self.idps.replace(map);
        });
      },
      async addIdp(idp: SnapshotIn<typeof Idp>) {
        const addedIdp = await addIdp(idp);
        self.runInAction(() => {
          // The following will automatically put addedIdp with entityId (because it's of type 'type.identifier') as Key
          // and addedIdp as Value
          self.idps.put(Idp.create(addedIdp));
        });
      },
      async updateIdp(entityId: string, idp: SnapshotIn<typeof Idp>) {
        // @ts-ignore
        const previousIdp = self.mustGetIdp(entityId);
        const updatedIdp = await updateIdp(entityId, previousIdp.rev, idp);
        self.runInAction(() => {
          // @ts-ignore
          applySnapshot(previousIdp, updatedIdp);
        });
      },
      async activateIdp(entityId: string) {
        // @ts-ignore
        const previousIdp = self.mustGetIdp(entityId);
        const updatedIdp = await activateIdp(entityId, previousIdp.rev);
        self.runInAction(() => {
          // @ts-ignore
          applySnapshot(previousIdp, updatedIdp);
        });
      },
      async deactivateIdp(entityId: string) {
        // @ts-ignore
        const previousIdp = self.mustGetIdp(entityId);
        const updatedIdp = await deactivateIdp(entityId, previousIdp.rev);
        self.runInAction(() => {
          // @ts-ignore
          applySnapshot(previousIdp, updatedIdp);
        });
      },
      async deleteIdp(entityId: string) {
        await deleteIdp(entityId);
        self.runInAction(() => {
          self.idps.delete(entityId);
        });
      },
      cleanup: () => {
        superCleanup();
      },
    };
  })
  .views(self => ({

    get empty() {
      return self.idps.size === 0;
    },
    get list() {
      return mapToArray<Instance<typeof Idp>>(self.idps);
    },
    getIdp(entityId: string): Instance<typeof Idp> | undefined {
      return self.idps.get(entityId);
    },
    mustGetIdp(entityId: string): Instance<typeof Idp> {
      const idp = self.idps.get(entityId);
      if (!idp) {
        throw new Error(`No IdP with entityId = ${entityId} found`);
      }
      return idp;
    },
  }));

function registerModels(models: ContextModels) {
  models.idpStore = IdpStore.create({}, models);
}

export { IdpStore, registerModels };

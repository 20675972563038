import React from "react";
import './App.css';
import { inject, observer } from 'mobx-react';
import { Instance } from "mobx-state-tree";
import {Switch, Redirect, withRouter, RouteComponentProps, Route} from 'react-router-dom';
import { Message, Icon, Container } from 'semantic-ui-react';

import { isStoreLoading, isStoreReady, isStoreError } from './models/BaseStore';
import MainLayout from './parts/MainLayout';
import Login from './parts/Login';
import RouterProps from "./models/RouterProps";
import {AppStore} from "./models/AppStore";
import ListIdps from "./parts/idp-management/ListIdps";
import LoginState from "./models/component-states/LoginState";
import AddIdp from "./parts/idp-management/AddIdp";
import EditIdp from "./parts/idp-management/EditIdp";
import ListAppClients from "./parts/app-client-management/ListAppClients";
import EditAppClient from "./parts/app-client-management/EditAppClient";
import AddAppClient from "./parts/app-client-management/AddAppClient";

interface OwnProps {
  // Declare regular properties here
}

type ComponentProps =
  RouterProps // Properties being injected by the React Router (via withRouter function)
  & { appStore: Instance<typeof AppStore> } // Properties being injected by Mobx React
  & RouteComponentProps<OwnProps> // Component's own regular properties

class App extends React.Component<ComponentProps> {

  render() {
    const { appStore } = this.props;
    let content = null;

    if (isStoreError(appStore.startup)) {
      // content = this.renderError()
      // Login below accepts props via mobx react injection
      // since we don't pass those props explicitly here TS things they are missing and hence it complains
      // TODO: figure out a better solution. For now, ignoring the TS error here
      // See some discussion at https://github.com/mobxjs/mobx-react/issues/256
      // @ts-ignore
      content = <Login loginState={LoginState.create()}/>;
    } else if (isStoreLoading(appStore.startup)) {
      content = this.renderProgress();
    } else if (isStoreReady(appStore.startup) && appStore.userAuthenticated ) {
      content = this.renderApp();
    } else {
      // @ts-ignore
      content = <Login loginState={LoginState.create()}/>;
    }
    return content;
  }

  renderApp() {
    // see https://reacttraining.com/react-router/web/api/withRouter
    const { location } = this.props;

    const defaultLocation = {
      pathname: '/idps',
      search: location.search, // we want to keep any query parameters
      hash: location.hash,
      state: location.state,
    };

    return (
      // MainLayout below accepts props via react router injection + mobx react injection
      // since we don't pass those props explicitly here TS things they are missing and hence it complains
      // TODO: figure out a better solution. For now, ignoring the TS error here
      // See some discussion at https://github.com/mobxjs/mobx-react/issues/256
      // @ts-ignore
      <MainLayout>
        <Switch>
          <Redirect exact from="/" to={defaultLocation}/>
          <Route path="/idps/edit/:entityId" component={EditIdp}/>
          <Route path="/idps/add" component={AddIdp}/>
          <Route path="/idps" component={ListIdps}/>
          <Route path="/app-clients/edit/:id" component={EditAppClient}/>
          <Route path="/app-clients/add" component={AddAppClient}/>
          <Route path="/app-clients" component={ListAppClients}/>
        </Switch>
      </MainLayout>
    );
  }

  renderProgress() {
    return (
      <Container text className="pt4">
        <Message icon>
          <Icon name="circle notched" loading/>
          <Message.Content>
            <Message.Header>Just one second</Message.Header>
            Loading application, please wait!
          </Message.Content>
        </Message>
      </Container>
    );
  }

  renderError() {
    const { appStore } = this.props;
    const message = `Something went wrong and the error message is ${appStore.startup.errorMessage}.  Please refresh your browser.`;
    return (
      <Container text className="pt4">
        <Message negative className="clearfix">
          <Message.Header>Oops!</Message.Header>
          <p>{message}</p>
        </Message>
      </Container>
    );
  }
}

export default inject( 'appStore')(withRouter(observer(App)));

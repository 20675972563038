import React from "react";
import {Instance} from "mobx-state-tree";
import {Button, Container, Header, Label, Modal, Segment} from "semantic-ui-react";
import {RouteComponentProps, withRouter} from "react-router";
import {inject, observer} from "mobx-react";
import _ from 'lodash';

import AppClient from "../../models/app-client/AppClient";
import {gotoFn} from "../../helpers/routing";
import {displayError, displaySuccess} from "../../helpers/notification";
import {AppClientStore} from "../../models/app-client/AppClientStore";
import AppClientListItemCardState from "../../models/component-states/AppClientListItemCardState";
import {getAuthFlowLabel} from "../../models/app-client/AuthFlow";

interface OwnProps {
  // Declare regular properties here
  appClient: Instance<typeof AppClient>,
  pos: number,
  appClientListItemCardState: Instance<typeof AppClientListItemCardState>
}

interface PathParams {
  // Declare any params coming from the router for example if you have "/some/url/:someParam" route
  // then declare "someParam" here
}

type ComponentProps =
  { appClientStore: Instance<typeof AppClientStore> } // Properties being injected by Mobx React
  & RouteComponentProps<PathParams> // Path params coming from the route url
  & OwnProps // Component's own regular properties that should be passed by the parent component

/**
 * Component for listing configured identity providers
 */
class AppClientListItemCard extends React.Component<ComponentProps> {

  componentWillUnmount(): void {
    this.getComponentState().setResetAppClient(undefined);
  }

  render() {
    const appClient = this.getAppClient();
    return (
      // The custom attribute "data-id" here is used for conveying the id of the virtualDatabase being clicked in the "handleVirtualDatabaseClick" handler
      <React.Fragment>
        <div className="flex-auto">
          <div className="flex">
            {this.renderIndexLabel()}
            <Header as="h2" color="grey" className="mt0">
              {appClient.name} <span className="pl2 fs-9 breakout">{appClient.id}</span>
            </Header>
            <div className="ml-auto">{this.renderActionButtons()}</div>
          </div>
        </div>
        <div>
          {this.renderCallbackUrls('Callback URLs', appClient.callbackUrls)}
          {this.renderCallbackUrls('Logout URLs', appClient.logoutUrls)}
          {this.renderAllowedOAuthFlows(appClient.allowedOAuthFlows)}
        </div>
        {this.renderConfirmDeleteDialog()}
        {this.renderResetSecretDialog()}
      </React.Fragment>
    );
  }

  protected renderCallbackUrls(title: string, urls: string[]) {
    return (
      <Segment vertical>{title}
        <div className='mt1'>
          {_.map(urls, url => < Label className='mt1 ml1'>{url}</Label>)}
        </div>
      </Segment>
    )
  }

  protected renderAllowedOAuthFlows(allowedOAuthFlows: string[]) {
    return (
      <Segment vertical>OAuth 2.0
        <div className='mt1 ml1'>Allowed OAuth Flows:
          <span>
            {!_.isEmpty(allowedOAuthFlows) && _.map(allowedOAuthFlows, allowedOAuthFlow =>
              <Label className='ml1'>{getAuthFlowLabel(allowedOAuthFlow)}</Label>)}
            {_.isEmpty(allowedOAuthFlows) &&
            <span className='ml1'><strong>No auth flows are enabled for this client</strong></span>}
          </span>
        </div>
      </Segment>
    )
  }

  protected getAppClient() {
    return this.props.appClient;
  }

  protected getStore() {
    return this.props.appClientStore;
  }

  protected getComponentState() {
    return this.props.appClientListItemCardState;
  }

  protected renderIndexLabel() {
    const pos = this.props.pos;
    return <Label size="mini" ribbon color="blue" className="line-height-20-px">{pos}</Label>;
  }

  protected renderActionButtons() {
    return (
      <React.Fragment>
        <Button content='Edit' icon='edit' primary onClick={this.handleEditClick} size='mini'/>
        <Button content='Reset Secret' icon='redo' color='orange' onClick={this.openResetSecretDialog} size='mini'/>
        <Button content='Delete' color='red' icon='trash' onClick={this.openConfirmDeleteDialog} size='mini'/>
      </React.Fragment>
    );
  }

  protected renderResetSecretDialog() {
    const componentState = this.getComponentState();
    const shouldShowDialog = componentState.shouldShowResetSecretDialog;
    const progress = componentState.resetSecretInProgress;
    const appClient = this.getAppClient();
    const id = appClient.id;
    const name = appClient.name;

    const resetAppClient = componentState.resetAppClient;
    const modalBody = resetAppClient

      ? <React.Fragment>
        <Modal.Header content="Application Client Secret Reset Successfully"/>
        <Modal.Content>
          <div>
            The secret for application client <strong>{name}</strong> with id <strong>{id}</strong> is reset
            successfully.
            The new client secret value is
          </div>
          <p>&nbsp;</p>
          <Label>
            <Container text style={{wordWrap: 'break-word'}}>{resetAppClient.secret}</Container>
          </Label>
          <p>&nbsp;</p>
          <div>
            The app client secret is displayed here only once. Please save it now.
            If you loose app client secret you will have to reset it again.
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Ok, I copied the secret. Close now." onClick={this.hideResetSecretDialog}/>
        </Modal.Actions>
      </React.Fragment>

      : <React.Fragment>
        <Modal.Header content="Reset Application Client Secret"/>
        <Modal.Content>
          <div>
            Are you sure you want to reset secret for application client <strong>{name}</strong> with
            id <strong>{id}</strong> ?
          </div>
          <div>
            This cannot be undone. Any client code that is using the previous secret value will have to be updated
            with new client secret or it may break.
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancel" disabled={progress} onClick={this.hideResetSecretDialog}/>
          <Button content='Yes, reset secret' icon='redo' color='orange' loading={progress} disabled={progress}
                  onClick={this.handleResetAppClientSecret}/>
        </Modal.Actions>
      </React.Fragment>;

    return (
      <Modal open={shouldShowDialog} size="large" onClose={this.hideResetSecretDialog} closeOnDimmerClick={false}>
        {modalBody}
      </Modal>
    );
  }

  protected renderConfirmDeleteDialog() {
    const componentState = this.getComponentState();
    const shouldShowDialog = componentState.shouldShowDeleteDialog;
    const progress = componentState.deleteInProgress;
    const appClient = this.getAppClient();
    const id = appClient.id;
    const name = appClient.name;
    return (
      <Modal open={shouldShowDialog} size="tiny" onClose={this.hideDeleteDialog} closeOnDimmerClick={!progress}>
        <Modal.Header content="Delete Application Client"/>
        <Modal.Content>
          <div>Are you sure you want to delete application client <strong>{name}</strong> with
            id <strong>{id}</strong> ?
          </div>
          <div>This cannot be undone. If you change your mind later, you will have to create the application client
            again.
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancel" disabled={progress} onClick={this.hideDeleteDialog}/>
          <Button content="Yes, delete" icon="remove" loading={progress} disabled={progress} color="red"
                  onClick={this.handleDeleteAppClient}/>
        </Modal.Actions>
      </Modal>
    );
  }

  protected openResetSecretDialog = () => {
    this.getComponentState().setShouldShowResetSecretDialog(true);
  };

  protected openConfirmDeleteDialog = () => {
    this.getComponentState().setShouldShowDeleteDialog(true);
  };

  protected hideDeleteDialog = () => {
    const componentState = this.getComponentState();
    const progress = componentState.deleteInProgress;
    if (progress) return;
    componentState.setShouldShowDeleteDialog(false);
  };

  protected hideResetSecretDialog = () => {
    const componentState = this.getComponentState();
    const progress = componentState.resetSecretInProgress;
    if (progress) return;
    componentState.setShouldShowResetSecretDialog(false);
    componentState.setResetAppClient(undefined);
  };

  protected handleDeleteAppClient = async () => {
    const id = this.getAppClient().id;
    const store = this.getStore();
    const componentState = this.getComponentState();
    componentState.setDeleteInProgress(true);
    try {
      await store.deleteAppClient(id);

      componentState.setDeleteInProgress(false);
      componentState.setShouldShowDeleteDialog(false);
      displaySuccess('The application client is deleted successfully', 'Deleted Application Client');
    } catch (error) {
      componentState.setDeleteInProgress(false);
      componentState.setShouldShowDeleteDialog(false);
      displayError(error);
    }
  };

  protected handleResetAppClientSecret = async () => {
    const id = this.getAppClient().id;
    const store = this.getStore();
    const componentState = this.getComponentState();
    componentState.setResetSecretInProgress(true);
    try {
      const resetAppClient = await store.resetAppClientSecret(id);

      componentState.setResetSecretInProgress(false);
      componentState.setResetAppClient(AppClient.create(resetAppClient));
    } catch (error) {
      componentState.setDeleteInProgress(false);
      componentState.setShouldShowDeleteDialog(false);
      displayError(error);
    }
  };

  protected handleEditClick = () => {
    const id = this.getAppClient().id;
    const goto = gotoFn(this);
    goto(`/app-clients/edit/${encodeURIComponent(id)}`);
  };
};

export default inject('appClientStore')(withRouter(observer(AppClientListItemCard)));

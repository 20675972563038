// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import _ from 'lodash';
import * as toastr from 'toastr';

function displayError(msg: any, error?: any, timeOut: string = '20000') {
  toastr.error(
    toMessage(msg, error),
    'We have a problem!',
    Object.assign({}, toasterErrorOptions, { timeOut }),
  );
  if (error) {
    console.error(msg, error);
  }
  if (_.isError(msg)) {
    console.error(msg);
  }
}

function displayWarning(msg: any, error: any, timeOut = '20000') {
  toastr.warning(
    toMessage(msg, error),
    'WARNING!',
    Object.assign({}, toasterWarningOptions, { timeOut }),
  );
  if (error) {
    console.error(msg, error);
  }
  if (_.isError(msg)) {
    console.error(msg);
  }
}

function displaySuccess(msg: { message: any; friendly?: any; } | string, title: string = 'Submitted!',
  overrideToasterOptions: ToastrOptions = {}) {
  let toasterOptions = toasterSuccessOptions;
  if (!_.isEmpty(overrideToasterOptions)) {
    toasterOptions = _.assign({}, toasterSuccessOptions, overrideToasterOptions);
  }
  toastr.success(toMessage(msg), title, toasterOptions);
}

function toMessage(msg: { message: any; friendly?: any; } | string, error?: { message: any; }) {
  // @ts-ignore
  if (msg && (msg.message || msg.friendly)) {
    // @ts-ignore
    return `${msg.message || msg.friendly} <br/>&nbsp;`;
  }

  if (_.isError(error)) {
    return `${msg} - ${error.message} <br/>&nbsp;`;
  }

  if (_.isArray(msg)) {
    const messages = msg;
    const size = _.size(messages);

    if (size === 0) {
      return 'Unknown error <br/>&nbsp;';
    } if (size === 1) {
      return `${messages[0]}<br/>&nbsp;`;
    }
    const result = [];
    result.push('<br/>');
    result.push('<ul>');
    _.forEach(messages, message => {
      result.push(`<li style="margin-left: -20px;">${message}</li>`);
    });
    result.push('</ul><br/>&nbsp;');

    return result.join('');
  }

  if (_.isEmpty(msg)) return 'Unknown error <br/>&nbsp;';

  return `${msg} <br/>&nbsp;`;
}

const toasterErrorOptions: ToastrOptions = {
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: true,
  positionClass: 'toast-top-right',
  preventDuplicates: true,
  timeOut: 20000, // 1000000
  extendedTimeOut: 50000, // 1000000
};

const toasterWarningOptions: ToastrOptions = {
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: true,
  positionClass: 'toast-top-right',
  preventDuplicates: true,
  timeOut: 20000, // 1000000
  extendedTimeOut: 50000, // 1000000
};

const toasterSuccessOptions: ToastrOptions = {
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: true,
  positionClass: 'toast-top-right',
  preventDuplicates: true,
  timeOut: 1000,
  extendedTimeOut: 10000,
};

export {
  displayError,
  displayWarning,
  displaySuccess,
  toasterSuccessOptions,
  toasterWarningOptions,
  toasterErrorOptions,
};

// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

import { observable } from 'mobx';

import registerModels from './helpers/register-models';
import postRegister from './helpers/post-register';
import KeyValuePairs from './models/KeyValuePairs';

// A global object containing all Mobx Stores and any other objects to put in the context
// All objects contained in contextModels are then passed to mobx-react "Provider"
// which in turn makes them available to be used in any React component using "inject" function from mobx-react
const contextModels = observable({});

export interface ContextModels {
  disposers: KeyValuePairs,
  intervalIds: KeyValuePairs,
  cleaner: KeyValuePairs,

  [propName: string]: any;
}

/**
 * The function to discover and register all models (usually mobx stores) to the contextModels.
 * All models registered to contextModels then are available to all react components using
 * Mobx-React provider / inject mechanism
 */
const initializeContextModels = (): ContextModels => {
  const contextHolder = {
    disposers: {},
    intervalIds: {},
    cleaner: {},
  };

  registerModels(contextHolder);

  // Method to allow any models to do any other initialization
  // work that requires all other models to be have been registered first
  postRegister(contextHolder);

  // ensure that it is the same contextModels reference that
  // is observed using mobx "observable"
  Object.assign(contextModels, contextHolder);

  return contextHolder;
};

export { contextModels, initializeContextModels };

// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// Licensed under the Amazon Software License
// http://aws.amazon.com/asl/

// ==================================================================
// Current User Store
// ==================================================================
import _ from 'lodash';
import { types } from 'mobx-state-tree';

import { getCurrentUser } from '../helpers/api';
import { BaseStore } from './BaseStore';
import { User } from './User';
import { ContextModels } from '../contextModels';

const CurrentUserStore = BaseStore
  .named('CurrentUserStore')
  .props({
    user: types.maybe(User),
  })
  .actions(self => {
    // save the base implementation of cleanup
    const superCleanup = self.cleanup;

    return {
      async doLoad() {
        const user = await getCurrentUser();
        self.runInAction(() => {
          self.user = User.create(user);
        });
      },
      cleanup: () => {
        self.user = undefined;
        superCleanup();
      },
    };
  })

  .views(self => ({
    get empty() {
      return _.isEmpty(self.user);
    },
  }));

function registerModels(models: ContextModels) {
  models.currentUserStore = CurrentUserStore.create({}, models);
}

export { CurrentUserStore, registerModels };
